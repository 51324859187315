import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import store from '@/store'
import { useLocalStorage } from '@vueuse/core'
import Layout from '@/components/Layout'
console.log(store.state.loginState)
const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/home',
        children: [{
            path: 'home',
            name: 'Home',
            component: () => import('../views/Home'),
            meta: { title: '首页' }
        }]
    },
    /* 登陆页 */
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        meta: {
            title: '登陆环信',
        },
    },
    
    /* 即开局房间 */
    {
        path: '/room',
        name: 'Room',
        redirect: '/room/opening',
        component: () => import('../views/Room/index.vue'),
        meta: {
            title: '即开局房间',
        },
        children: [
            /* 会话列表 */
            {
                path: 'opening',
                name: 'opening',
                meta: {
                    title: '即开局房间'
                },
                component: () => import('../views/Room/opening.vue')
            },
            /* dm投屏带本 */
            {
                path: 'pcscreen',
                name: 'pcscreen',
                meta: {
                    title: 'dm投屏带本'
                },
                component: () => import('../views/Room/pcscreen.vue')
            },
            /* 玩家投屏看本 */
            {
                path: 'playerscreen',
                name: 'playerscreen',
                meta: {
                    title: '玩家投屏'
                },
                component: () => import('../views/Room/playerscreen.vue')
            }
        ]

    },
    /* 个人中心 */
    {
        path: '/personal',
        name: 'Personal',
        component: Layout,
        redirect: '/personal/index',
        children: [
            /* 会话列表 */
            {
                path: 'index',
                name: 'personalIndex',
                meta: {
                    title: '个人中心'
                },
                component: () => import('../views/Personal/index.vue')
            }
        ]

    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
    routes,
})
router.beforeEach((to, from, next) => {
    NProgress.start()
    // const loginState = store.state.loginState
    const loginUserFromStorage = useLocalStorage('loginUser',{})
    console.log('to',to.path)
    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    //需要登陆
    if (loginUserFromStorage.value?.userId && loginUserFromStorage.value?.pccToken) {
        //token存在，放行
        next()
        NProgress.done()
    } else if(to.path === '/login'){
        //token不存在，跳转登陆页面
        next()
        NProgress.done()
    }else{
        //token不存在，跳转登陆页面
        next({ path: '/login' })
        NProgress.done()
    }
    // } else {
    //     next()
    //     NProgress.done()
    // }
})
export default router
