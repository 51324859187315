<template>
    <div class='voicesetting_body' :class='[`${themes}`]'>
        <div class='device_list'>
            <div class='device_item'>
                <div class='setting_label'>
                    <span>输入设备</span>
                    <span class="small" @click="initDevices(null)">检测可用设备</span>
                </div>
                <el-select v-model='inputDevice' :disabled="inputDeviceDisabled" class='device_item_select' placeholder='请选择输入设备' size='large'
                           @change='onChangeInputDevice'>
                    <el-option
                        v-for='item in inputDeviceOptions'
                        :key='item.deviceId'
                        :label='item.label'
                        :value='item.deviceId'
                    />
                </el-select>
                <!-- <div class='setting_label'>
                    输入音量
                </div>
                <div class='device_item_slider'>
                    <el-slider v-model='inputVolume' @input='handleIntput' />
                </div> -->
            </div>
            <!-- <div class='device_item'>
                <div class='setting_label'>
                    输出设备
                </div>
                <el-select v-model='outputDevice' class='device_item_select' placeholder='请选择输出设备' size='large' @change='onChangeOutputDevice'>
                    <el-option
                        v-for='item in outputDeviceOptions'
                        :key='item.deviceId'
                        :label='item.label'
                        :value='item.deviceId'
                    />
                </el-select>
                <div class='setting_label'>
                    输出音量
                </div>
                <div class='device_item_slider'>
                    <el-slider v-model='outputVolume' @input='handleOutput' />
                </div>
            </div> -->
        </div>
        <div class='micro_test_section'>
            <div class='setting_label'>
                麦克风测试
            </div>
            <div class='setting_label tip'>
                点击开始测试麦克风，我们会把麦克风收到的声音显示出来
            </div>
            <div class='micro_test_body'>
                <div class='test_btn' @click='startTest'>{{ isTesting ?'停止测试':'测试一下'}}</div>
                <div class='test_result'>
                    <div class='test_volume_item' v-for='(item, index) in totalVolumes' :key='index'
                         :class='{active:volume.value >= (index * 2 + 1)}'></div>
                    <!-- <el-slider v-model="volume.value" /> -->
                </div>
            </div>
        </div>
        <!--        <audio controls ref='sound'>-->
        <!--            <source-->
        <!--                src='http://m801.music.126.net/20230831161248/b13f91622fe1f9ae74f7592ae54a7491/jdyyaac/0e0c/525e/0e5f/9fc334d9e0e4a4af994c95bdad0505a1.m4a'-->
        <!--                type='audio/ogg'>-->
        <!--        </audio>-->
    </div>
</template>

<script setup>
import useVolume from '@/hooks/useVolume.js'
import { ElMessage } from 'element-plus'
import { onMounted, ref, toRefs,computed,watch, nextTick } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const inputDevice = computed(()=>{
    return store.state.inputDeviceId
})
const outputDevice = computed(()=>{
    return store.state.outputDeviceId
})
const inputDeviceOptions = ref([])
const inputVolume = computed(()=>{
    console.log('inputVolume变化了------')
    return localStorage.getItem('inputVolume') ? Number(localStorage.getItem('inputVolume')) : 100
})
const outputDeviceOptions = ref([])
const outputVolume = computed(()=>{
    console.log('outputVolume变化了------')
    return localStorage.getItem('outputVolume') ? Number(localStorage.getItem('outputVolume')) : 100
})
const props = defineProps({
    themes: {
        type: String,
        default: 'default'
    }
})
const { themes } = toRefs(props)

const inputDeviceDisabled = ref(false)
const initDevices = (cb = null) => {
    navigator.mediaDevices.enumerateDevices().then(allDevices => {
        console.log('allDevices', allDevices)
        inputDeviceOptions.value = []
        outputDeviceOptions.value = []
        allDevices.forEach(element => {
            // console.log('element---', element)
            if (element.kind === 'audioinput' && element.deviceId) {
                inputDeviceOptions.value.push(element)
            } else if (element.kind === 'audiooutput' && element.deviceId) {
                outputDeviceOptions.value.push(element)
            }
        })
        if(!inputDeviceOptions.value.length){
            inputDeviceOptions.value = [{
                label:'未发现音频输入设备，请检查麦克风或权限',
                deviceId:'default'
            }]
            inputDeviceDisabled.value = true
            navigator.mediaDevices.getUserMedia({
                video: false, audio: true
            }).then(() => {
                console.log('success')
            }).catch(err=>{
                console.log('faile',err.message)
                if(err.message.indexOf('Permission denied') > -1){
                    ElMessage({
                        message:'请开启麦克风权限后刷新页面',
                        type:'warning',
                        customClass:'jyx_message'
                    })
                }
            })
        }else{
            inputDeviceDisabled.value = false
            store.commit('SET_INPUTDEVICEID',inputDeviceOptions.value[0].deviceId)
        }
        cb && cb()
        // default代表默认的deviceId
        // inputDevice.value = 'default'
        // outputDevice.value = 'default'
    })

}
initDevices()
const volume = ref(0)
const startTest = () => {
    if(isTesting.value){
        window.nbilog.push('volumeTest_stop_click')
        // 停止测试
        console.log('停止测试')
        if(currentAudiotrack.value){
            currentAudiotrack.value.enabled = false
            isTesting.value = false
        }
    }else{
        window.nbilog.push('volumeTest_start_click')
        getInputDevice(true)
    }
    
}
const onChangeInputDevice = (val) => {
    if (val) {
        store.commit('SET_INPUTDEVICEID',val)
        getInputDevice()
    }
}
const onChangeOutputDevice = (val)=>{
    if (val) {
        store.commit('SET_OUTPUTDEVICEID',val)
    }
}
const currentAudiotrack = ref(null)
const isTesting = ref(false)
const getInputDevice = (changeTesting = false) => {
    navigator.mediaDevices
        .getUserMedia({
            video: false, audio: {
                deviceId: inputDevice.value
            }
        })
        .then((s) => {
            console.log('videoMediaStream.getAudioTracks()',)
            if(changeTesting){
                isTesting.value = !isTesting.value
            }
            currentAudiotrack.value = s.getAudioTracks()[0]
            currentAudiotrack.value.enabled = isTesting.value
            volume.value = useVolume(s)
        }).catch(err=>{
            console.log('faile',err.message)
            if(err.message.indexOf('Permission denied') > -1){
                ElMessage({
                    message:'请开启麦克风权限后刷新页面',
                    type:'warning',
                    customClass:'jyx_message'
                })
            }
        })
}
const changeOutputDevice = () => {
    // speakerAudio.current.setSinkId(item[0].deviceId)
}
const totalVolumes = 50

// 输出音量设置
// const sound=ref()
const audio = new Audio()
const handleOutput = () => {
    audio.volume = outputVolume.value / 100
    // sound.value.volume=audio.volume
    console.log(audio.volume)
    localStorage.setItem('outputVolume', outputVolume.value)
}
// 输入音量设置
const handleIntput = () => {
    localStorage.setItem('inputVolume', inputVolume.value)
}
const changedDevice = computed(()=>{
    return store.state.changedDevice
})
watch(() => changedDevice.value, (newVal) => {
    console.log('changedDevice变化了', newVal)
    initDevices(()=>{
        if (newVal && !newVal.startsWith('CHANGEDEVICE_')) {
            store.commit('SET_INPUTDEVICEID',newVal)
        }else{
            if(inputDeviceOptions.value.length){
                store.commit('SET_INPUTDEVICEID',inputDeviceOptions.value[0].deviceId)
            }
        }
    })
    
})
onMounted(() => {
    audio.volume = outputVolume.value / 100
    console.log(audio.volume)
})
defineExpose({
    initDevices
})

</script>

<style lang='scss' scoped>
.voicesetting_body {
    .device_list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .device_item {
            flex: 1;
            padding: 0px 15px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            .device_item_select {
                margin-bottom: 30px;

                ::v-deep .el-input__wrapper {
                    box-shadow: none !important;
                    border-radius: 7px;
                    padding: 11px 20px;
                    transition: none;
                    background-color: var(--main-bg-lighter-color-3);

                    .el-input__inner {
                        color: #fff;
                        opacity: 0.8;
                    }

                    .el-input__count {
                        color: #fff;
                        opacity: 0.3;
                    }

                    .el-input__count-inner {
                        background: none;
                    }
                }
            }

            .device_item_slider {
                // padding-left: 10px;
                ::v-deep .el-slider {
                    .el-slider__runway {
                        height: 14px;
                        background-color: var(--main-bg-lighter-color-3);
                        border-radius: 14px;

                        .el-slider__bar {
                            height: 14px;
                            border-top-left-radius: 14px;
                            border-bottom-left-radius: 14px;
                            background: var(--mian-button-linear-gradient-color2);
                        }

                        .el-slider__button-wrapper {
                            top: -10px;

                            .el-slider__button {
                                height: 28px;
                                width: 28px;
                                border: none;
                                background-color: var(--main-button-color-4);
                            }
                        }

                    }
                }
            }
        }
    }


    .setting_label {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        margin-bottom: 10px;
        height: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .small{
            font-size: 12px;
            cursor: pointer;
        }
        &.tip {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            opacity: 0.6;
        }
    }

    .micro_test_section {
        padding: 0px 15px;
        margin-top: 30px;

        .micro_test_body {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .test_btn {
                width: 110px;
                height: 40px;
                border-radius: 6px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                background: var(--mian-button-linear-gradient-color);

                &:hover {
                    opacity: 0.9;
                }
            }

            .test_result {
                margin-left: 20px;
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: space-around;

                .test_volume_item {
                    width: 6px;
                    height: 30px;
                    border-radius: 30px;
                    background-color: var(--main-bg-lighter-color-3);

                    &.active {
                        background-color: var(--main-bg-lighter-color-7);
                    }
                }
            }
        }
    }

    &.dark {
        .setting_label {
            opacity: 0.66;

            &.tip {
                opacity: 0.3;
            }
        }

        .device_item {
            .device_item_select {
                ::v-deep .el-input__wrapper {
                    background-color: var(--main-bg-darker-color7);
                }
            }

            .device_item_slider {
                // padding-left: 10px;
                ::v-deep .el-slider {
                    .el-slider__runway {
                        height: 7.5px;
                        background-color: var(--main-bg-lighter-color);
                        border-radius: 1px;

                        .el-slider__bar {
                            height: 7.5px;
                            border-top-left-radius: 1px;
                            border-bottom-left-radius: 1px;
                            background: var(--main-bg-lighter-color-9);
                        }

                        .el-slider__button-wrapper {
                            top: -14px;

                            .el-slider__button {
                                height: 17px;
                                width: 17px;
                                border: none;
                                background-color: var(--main-button-color-5);
                            }
                        }
                    }
                }
            }
        }

        .micro_test_section {
            .micro_test_body {
                .test_btn {
                    background: var(--main-button-color-6);
                    color: rgba($color: #fff, $alpha: 0.6);
                }

                .test_result {
                    padding: 7px 9px;
                    justify-content: center;
                    border-radius: 3px;
                    /* box-sizing: border-box; */
                    /* height: 40px; */
                    background: var(--main-bg-darker-color7);

                    .test_volume_item {
                        width: 4px;
                        height: 8px;
                        transform: skewX(-40deg);
                        border-radius: 1px;
                        margin-left: 4px;

                        &.active {
                            background-color: var(--main-bg-lighter-color-9);
                        }
                    }
                }
            }
        }

    }
}


</style>
