import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c98aaaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-reference-user" }
const _hoisted_2 = { class: "nick" }
const _hoisted_3 = { class: "colon" }
const _hoisted_4 = {
  key: 0,
  class: "face-box"
}
const _hoisted_5 = {
  key: 0,
  class: "text-box"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 3,
  class: "message-video-cover"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.referenceMessage?.messageID && _ctx.allMessageID.indexOf(_ctx.referenceMessage.messageID) !== -1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`${_ctx.type} ${_ctx.message.flow === 'in' && _ctx.type + '-reverse'}`)
      }, [
        _createElementVNode("p", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.referenceForShow.nick || _ctx.referenceForShow.from), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.type === 'reference' ? ':' : ''), 1)
        ]),
        (_ctx.referenceMessage.messageType === _ctx.constant.typeText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.face, (item, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  (item.name === 'text')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.text), 1))
                    : (item.name === 'img')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "text-img",
                          src: item.src
                        }, null, 8, _hoisted_6))
                      : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.referenceMessage.messageType === _ctx.constant.typeCustom)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.referenceMessage.messageAbstract), 1))
          : _createCommentVNode("", true),
        (_ctx.referenceMessage.messageType === _ctx.constant.typeImage)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              class: "message-img",
              src: _ctx.referenceForShow.payload.imageInfoArray[1].url
            }, null, 8, _hoisted_8))
          : _createCommentVNode("", true),
        (_ctx.referenceMessage.messageType === _ctx.constant.typeVideo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("img", {
                class: "message-videoimg",
                src: _ctx.referenceForShow?.payload?.snapshotUrl || _ctx.referenceForShow?.payload?.thumbUrl
              }, null, 8, _hoisted_10)
            ]))
          : _createCommentVNode("", true),
        (_ctx.referenceMessage.messageType === _ctx.constant.typeFace)
          ? (_openBlock(), _createElementBlock("img", {
              key: 4,
              class: "message-img",
              src: _ctx.url
            }, null, 8, _hoisted_11))
          : _createCommentVNode("", true),
        (_ctx.referenceMessage.messageType === _ctx.constant.typeFile || _ctx.referenceMessage.messageType === _ctx.constant.typeAudio)
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.referenceMessage?.messageAbstract), 1))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(`${_ctx.type} ${_ctx.message.flow === 'in' && _ctx.type + '-reverse'}`)
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.referenceMessage?.messageSender), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.referenceMessage?.messageAbstract), 1)
      ], 2))
}