<script setup>
import { useStore } from 'vuex'
import { useLocalStorage } from '@vueuse/core'
import ring from '@/assets/ring.mp3'
import { ref,computed,watch } from 'vue'
import { NBilog } from '@/utils/common'
import { ElLoading } from 'element-plus'

window.nbilog = new NBilog(89)
const store = useStore()
console.log('process.env',process.env)
// 主题设置
const LOCAL_KEY = '__theme__'
const theme =ref(localStorage.getItem(LOCAL_KEY) || 'default')
watch(
    () => theme.value,
    (newVal) => {
        console.log('当前设置的'+LOCAL_KEY,newVal)
        document.documentElement.dataset.theme = theme.value
        localStorage.setItem(LOCAL_KEY,theme.value)
    },
    {
        immediate: true
    })

const globalDialog = computed(()=>{
    return store.state.globalDialog
})
const closeGlobalDialog = ()=>{
    store.commit('CLOSE_GLOBALDIALOG')
}
const enterloading = computed(()=>{
    return store.state.enterloading
})
const showEnterLoading = ref(null)
watch(()=>enterloading.value,(newVal)=>{
    if(newVal){
        showEnterLoading.value = ElLoading.service({
            lock: true,
            text: '进入房间中...',
            background: 'rgba(0, 0, 0, 0.7)'
        })
    }else{
        showEnterLoading.value && showEnterLoading.value.close()
    }
})
/* 重新登陆 */
//读取本地EASEIM_loginUser
const loginUserFromStorage = useLocalStorage('loginUser',{})
console.log('App.vue中的loginUserFromStorage',loginUserFromStorage.value)
if (loginUserFromStorage.value?.userId && loginUserFromStorage.value?.txSig) {
    // console.log('重新登陆',TIMOptions)
    // EaseChatClient.open({
    //     user: loginUserFromStorage.value.imUserName,
    //     accessToken: loginUserFromStorage.value.imToken
    // })
    // TIMLogin(loginUserFromStorage.value.userId,loginUserFromStorage.value.txSig)
}
if (loginUserFromStorage.value?.userId && loginUserFromStorage.value?.pccToken) {
    console.log('获取我的基本信息')
    store.dispatch('getUserBasicInfo')
}

</script>
<template>
    <router-view v-slot="{ Component }">
        <transition
            name="slide-fade"
            mode="out-in"
            :duration="{ enter: 500, leave: 300 }"
        >
            <component :is="Component" />
        </transition>
    </router-view>
    <!-- 铃声标签 -->
    <audio id="ring" :src="ring" controls hidden></audio>
    <!-- About EaseCallKit -->
    <!-- <EaseCallKit
        ref="easeCallKit"
        :EaseIMClient="EaseChatClient"
        :msgCreateFunc="EaseChatSDK.message"
        @onInviteMembers="showModal"
    />
    <InviteCallMembers
        ref="inviteCallComp"
        @sendMulitInviteMsg="sendMulitInviteMsg"
    /> -->
    <!-- 全局的dialog弹窗提示 -->
    <el-dialog width='430px' :close-on-click-modal="true" v-model='globalDialog.dialogVisible' append-to-body
                   custom-class='common_dialog dark noHeader'>
            <div class='common_dialog_body center'>
                <div class='common_dialog_tips'>
                    {{ globalDialog.message }}
                </div>
                <div class='common_dialog_actions flex'>
                    <div class='common_dialog_action_btn confirm' @click='closeGlobalDialog'>{{ globalDialog.btnText }}</div>
                </div>
            </div>

        </el-dialog>
</template>

<style lang="scss">
@import './styles/reset/reset.css';
@import './styles/iconfont/iconfont.css';

/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0.3;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
} */
    .globalDialog_body{
        .globalDialog_content{
            text-align: center;
            padding:12px 20px;
        }
        .globalDialog_actions{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            .globalDialog_action_item{
                width: 100px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                background-color: #ffc357;
                color: #252525;
                font-size: 14px;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }
 html{
    height: 100%;
 }
 body{
    height: 100%;
 }
 #app{
    height: 100%;
 }   
 .el-select__popper {
    background: var(--main-bg-lighter-color-6) !important;
    border: none !important;
    border-radius: 7.652px;
    box-shadow: 4px 7px 19px 0px rgba(51, 54, 84, 0.52);
    margin-top: -3px !important;

    .el-popper__arrow {
        display: none;
    }

    .el-select-dropdown {
        &__item {
            height: 58px;
            line-height: 58px;
            color: rgba($color: #fff, $alpha: 0.4);
            margin: 0 20px;
            border-bottom: 1px solid rgba($color: #DEE2F8, $alpha: 0.1);

            &.selected {
                color: rgba($color: #fff, $alpha: 1);
                font-weight: bold;
            }

            &.hover, &:hover {
                background-color: rgba($color: #fff, $alpha: 0.05);
            }
        }
    }
}   
</style>
