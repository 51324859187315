<template>
    <div class='header_container'>
        <div class='header_left' @click='goHome'>
            <img src='@/assets/logo.png' class='logo'>
            <img src='@/assets/juyouxi.png' class='title'>
        </div>
        <div class='header_middle'>

        </div>
        <div class='header_right'>
            <template v-if='loginUser && loginUser.userId && loginUser.pccToken'>
                <div class='userinfo hoverTxt' @click='onPropfile'>
                    <span class='username'>{{ userBasicInfo.userName }}</span>
                    <el-image :src="userBasicInfo.userAvator+ '?imageView2/2/w/100/h/100'" fit='cover' class='user_avatar' :lazy='true'>
                        <template #error>
                            <div class='image-slot'>
                                <i class='el-icon-user' />
                            </div>
                        </template>
                    </el-image>
                </div>
                <div class='setting_btn' @click='showSettingDialog'>
                    <img src='@/assets/icons/setting.png' alt=''>
                    <span>语音设置</span>
                </div>
                <div class='logout hoverTxt'>
                    <img src='@/assets/icons/logout.png' class='logoutIcon' @click='logout.dialogVisible = true' />
                </div>
            </template>
            <template v-else>
                <div class='login_btn' @click='goLogin'>
                    登录
                </div>
            </template>
        </div>
<!--        <el-dialog-->
<!--            custom-class='common_dialog'-->
<!--            v-model='settingDialogVisible'-->
<!--            v-if='settingDialogVisible'-->
<!--            :close-on-click-modal='false'-->
<!--            :modal='true'-->
<!--            :show-close='false'-->
<!--            width='800px'>-->
<!--            <template #header='{ close }'>-->
<!--                <div class='common_dialog_header'>-->
<!--                    <span class='common_dialog_title'>通用语音设置</span>-->
<!--                    <img src='@/assets/icons/close.png' class='common_dialog_close' @click='close' />-->
<!--                </div>-->
<!--            </template>-->
<!--            <div class='common_dialog_body'>-->
<!--                <VoiceSetting />-->
<!--            </div>-->
<!--        </el-dialog>-->
        <Seetings ref="settingsRef" />

        <!-- 退出登陆模块 -->
        <Logout ref='logout' />
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import store from '@/store'
import Logout from './Logout.vue'
import VoiceSetting from '@/components/VoiceSetting/index.vue'
import router from '@/router'
import Seetings from '@/views/Room/components/Dialog/Settings.vue'

const settingsRef = ref(null)
const userBasicInfo = computed(() => store.state.userBasicInfo)
const loginUserFromStorage = useLocalStorage('loginUser', {})
const loginUser = computed(() => {
    return loginUserFromStorage.value
})
const logout = ref(null)
const onPropfile = () => {
    // console.log('准备跳转到个人中心')
    // router.push('/personal/index')
}
const goHome = () => {
    console.log('准备跳转到首页')
    router.push('/home')
}
const goLogin = () => {
    console.log('准备跳转到登录页')
    router.push('/login')
}
const settingDialogVisible = ref(false)
const showSettingDialog = () => {
    // settingDialogVisible.value = true
    settingsRef.value.openDialog()

}
</script>

<style lang='scss' scoped>

.header_container {
    display: flex;
    align-items: center;
    height: 60px;
    color: rgba(255, 255, 255, 0.70);

    .header_left {
        display: flex;
        align-items: center;
        cursor: pointer;

        .logo {
            width: 32px;
            height: 32px;
        }

        .title {
            width: 60px;
            // height: 25px;
            margin-left: 8px;
        }
    }

    .header_middle {
        display: flex;
        align-items: center;
        flex: 1;
    }

    .header_right {
        display: flex;
        align-items: center;

        .userinfo {
            display: flex;
            align-items: center;
            //cursor: pointer;

            &.hoverTxt {

            }

            .user_avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-left: 6px;
            }

            .image-slot {

            }

            .username {
                font-size: 14px;
                margin-right: 6px;
            }
        }

        .logout {
            display: flex;
            align-items: center;
            cursor: pointer;

            .logoutIcon {
                width: 24px;
                height: 24px;
            }
        }

        .login_btn {
            cursor: pointer;
        }

        .setting_btn {
            padding: 6px 8px;
            height: 24px;
            background-color: var(--main-button-color-3);
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.70);
            margin-right: 20px;
            margin-left: 20px;

            &:hover {
                background-color: var(--main-button-color-2);
            }

            img {
                width: 20px;
                height: 18px;
                margin-right: 4px;
            }
        }

    }
}


</style>
