import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/emoji.png'


const _withScopeId = n => (_pushScopeId("data-v-787a3e7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "face",
  id: "face"
}
const _hoisted_2 = { class: "face-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      class: "face_icon",
      alt: "",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }),
    _withDirectives(_createElementVNode("main", {
      class: _normalizeClass(["face-main-group", [_ctx.isH5 && 'face-H5-main']]),
      ref: "dialog"
    }, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expressionPath, (childrenItem, key) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "face-list-item",
            key: key,
            onClick: ($event: any) => (_ctx.select(childrenItem, key))
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/images/expression/${childrenItem}`)
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3))
        }), 128))
      ])
    ], 2), [
      [_vShow, _ctx.show]
    ])
  ]))
}