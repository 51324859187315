import { enterRoom, getRoomAlldata } from '@/api/room'

const Room = {
    state: {
    },
    mutations: {

    },
    actions: {
        //获取历史消息
        fetchEnterRoomInfo: async ({ state, dispatch, commit }, roomId) => {
            return new Promise((resolve, reject) => {
                enterRoom(roomId).then((res) => {
                    resolve(res)
                })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        fetchRoomData: async ({ state, dispatch, commit }, roomId) => {
            return new Promise((resolve, reject) => {
                getRoomAlldata(roomId).then((res) => {
                    resolve(res)
                })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
    }
}
export default Room
