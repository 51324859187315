export const emojiUrl = 'https://web.sdk.qcloud.com/im/assets/emoji/';
export const emojiMap:any = {
  '[NO]': 'emoji_0@2x.png',
  '[OK]': 'emoji_1@2x.png',
  '[下雨]': 'emoji_2@2x.png',
  '[么么哒]': 'emoji_3@2x.png',
  '[乒乓]': 'emoji_4@2x.png',
  '[便便]': 'emoji_5@2x.png',
  '[信封]': 'emoji_6@2x.png',
  '[偷笑]': 'emoji_7@2x.png',
  '[傲慢]': 'emoji_8@2x.png',
  '[再见]': 'emoji_9@2x.png',
  '[冷汗]': 'emoji_10@2x.png',
  '[凋谢]': 'emoji_11@2x.png',
  '[刀]': 'emoji_12@2x.png',
  '[删除]': 'emoji_13@2x.png',
  '[勾引]': 'emoji_14@2x.png',
  '[发呆]': 'emoji_15@2x.png',
  '[发抖]': 'emoji_16@2x.png',
  '[可怜]': 'emoji_17@2x.png',
  '[可爱]': 'emoji_18@2x.png',
  '[右哼哼]': 'emoji_19@2x.png',
  '[右太极]': 'emoji_20@2x.png',
  '[右车头]': 'emoji_21@2x.png',
  '[吐]': 'emoji_22@2x.png',
  '[吓]': 'emoji_23@2x.png',
  '[咒骂]': 'emoji_24@2x.png',
  '[咖啡]': 'emoji_25@2x.png',
  '[啤酒]': 'emoji_26@2x.png',
  '[嘘]': 'emoji_27@2x.png',
  '[回头]': 'emoji_28@2x.png',
  '[困]': 'emoji_29@2x.png',
  '[坏笑]': 'emoji_30@2x.png',
  '[多云]': 'emoji_31@2x.png',
  '[大兵]': 'emoji_32@2x.png',
  '[大哭]': 'emoji_33@2x.png',
  '[太阳]': 'emoji_34@2x.png',
  '[奋斗]': 'emoji_35@2x.png',
  '[奶瓶]': 'emoji_36@2x.png',
  '[委屈]': 'emoji_37@2x.png',
  '[害羞]': 'emoji_38@2x.png',
  '[尴尬]': 'emoji_39@2x.png',
  '[左哼哼]': 'emoji_40@2x.png',
  '[左太极]': 'emoji_41@2x.png',
  '[左车头]': 'emoji_42@2x.png',
  '[差劲]': 'emoji_43@2x.png',
  '[弱]': 'emoji_44@2x.png',
  '[强]': 'emoji_45@2x.png',
  '[彩带]': 'emoji_46@2x.png',
  '[彩球]': 'emoji_47@2x.png',
  '[得意]': 'emoji_48@2x.png',
  '[微笑]': 'emoji_49@2x.png',
  '[心碎了]': 'emoji_50@2x.png',
  '[快哭了]': 'emoji_51@2x.png',
  '[怄火]': 'emoji_52@2x.png',
  '[怒]': 'emoji_53@2x.png',
  '[惊恐]': 'emoji_54@2x.png',
  '[惊讶]': 'emoji_55@2x.png',
  '[憨笑]': 'emoji_56@2x.png',
  '[手枪]': 'emoji_57@2x.png',
  '[打哈欠]': 'emoji_58@2x.png',
  '[抓狂]': 'emoji_59@2x.png',
  '[折磨]': 'emoji_60@2x.png',
  '[抠鼻]': 'emoji_61@2x.png',
  '[抱抱]': 'emoji_62@2x.png',
  '[抱拳]': 'emoji_63@2x.png',
  '[拳头]': 'emoji_64@2x.png',
  '[挥手]': 'emoji_65@2x.png',
  '[握手]': 'emoji_66@2x.png',
  '[撇嘴]': 'emoji_67@2x.png',
  '[擦汗]': 'emoji_68@2x.png',
  '[敲打]': 'emoji_69@2x.png',
  '[晕]': 'emoji_70@2x.png',
  '[月亮]': 'emoji_71@2x.png',
  '[棒棒糖]': 'emoji_72@2x.png',
  '[汽车]': 'emoji_73@2x.png',
  '[沙发]': 'emoji_74@2x.png',
  '[流汗]': 'emoji_75@2x.png',
  '[流泪]': 'emoji_76@2x.png',
  '[激动]': 'emoji_77@2x.png',
  '[灯泡]': 'emoji_78@2x.png',
  '[炸弹]': 'emoji_79@2x.png',
  '[熊猫]': 'emoji_80@2x.png',
  '[爆筋]': 'emoji_81@2x.png',
  '[爱你]': 'emoji_82@2x.png',
  '[爱心]': 'emoji_83@2x.png',
  '[爱情]': 'emoji_84@2x.png',
  '[猪头]': 'emoji_85@2x.png',
  '[猫咪]': 'emoji_86@2x.png',
  '[献吻]': 'emoji_87@2x.png',
  '[玫瑰]': 'emoji_88@2x.png',
  '[瓢虫]': 'emoji_89@2x.png',
  '[疑问]': 'emoji_90@2x.png',
  '[白眼]': 'emoji_91@2x.png',
  '[皮球]': 'emoji_92@2x.png',
  '[睡觉]': 'emoji_93@2x.png',
  '[磕头]': 'emoji_94@2x.png',
  '[示爱]': 'emoji_95@2x.png',
  '[礼品袋]': 'emoji_96@2x.png',
  '[礼物]': 'emoji_97@2x.png',
  '[篮球]': 'emoji_98@2x.png',
  '[米饭]': 'emoji_99@2x.png',
  '[糗大了]': 'emoji_100@2x.png',
  '[红双喜]': 'emoji_101@2x.png',
  '[红灯笼]': 'emoji_102@2x.png',
  '[纸巾]': 'emoji_103@2x.png',
  '[胜利]': 'emoji_104@2x.png',
  '[色]': 'emoji_105@2x.png',
  '[药]': 'emoji_106@2x.png',
  '[菜刀]': 'emoji_107@2x.png',
  '[蛋糕]': 'emoji_108@2x.png',
  '[蜡烛]': 'emoji_109@2x.png',
  '[街舞]': 'emoji_110@2x.png',
  '[衰]': 'emoji_111@2x.png',
  '[西瓜]': 'emoji_112@2x.png',
  '[调皮]': 'emoji_113@2x.png',
  '[象棋]': 'emoji_114@2x.png',
  '[跳绳]': 'emoji_115@2x.png',
  '[跳跳]': 'emoji_116@2x.png',
  '[车厢]': 'emoji_117@2x.png',
  '[转圈]': 'emoji_118@2x.png',
  '[鄙视]': 'emoji_119@2x.png',
  '[酷]': 'emoji_120@2x.png',
  '[钞票]': 'emoji_121@2x.png',
  '[钻戒]': 'emoji_122@2x.png',
  '[闪电]': 'emoji_123@2x.png',
  '[闭嘴]': 'emoji_124@2x.png',
  '[闹钟]': 'emoji_125@2x.png',
  '[阴险]': 'emoji_126@2x.png',
  '[难过]': 'emoji_127@2x.png',
  '[雨伞]': 'emoji_128@2x.png',
  '[青蛙]': 'emoji_129@2x.png',
  '[面条]': 'emoji_130@2x.png',
  '[鞭炮]': 'emoji_131@2x.png',
  '[风车]': 'emoji_132@2x.png',
  '[飞吻]': 'emoji_133@2x.png',
  '[飞机]': 'emoji_134@2x.png',
  '[饥饿]': 'emoji_135@2x.png',
  '[香蕉]': 'emoji_136@2x.png',
  '[骷髅]': 'emoji_137@2x.png',
  '[麦克风]': 'emoji_138@2x.png',
  '[麻将]': 'emoji_139@2x.png',
  '[鼓掌]': 'emoji_140@2x.png',
  '[龇牙]': 'emoji_141@2x.png',
};
export const emojiName:Array<string> = [
  '[龇牙]',
  '[调皮]',
  '[流汗]',
  '[偷笑]',
  '[再见]',
  '[敲打]',
  '[擦汗]',
  '[猪头]',
  '[玫瑰]',
  '[流泪]',
  '[大哭]',
  '[嘘]',
  '[酷]',
  '[抓狂]',
  '[委屈]',
  '[便便]',
  '[炸弹]',
  '[菜刀]',
  '[可爱]',
  '[色]',
  '[害羞]',
  '[得意]',
  '[吐]',
  '[微笑]',
  '[怒]',
  '[尴尬]',
  '[惊恐]',
  '[冷汗]',
  '[爱心]',
  '[示爱]',
  '[白眼]',
  '[傲慢]',
  '[难过]',
  '[惊讶]',
  '[疑问]',
  '[困]',
  '[么么哒]',
  '[憨笑]',
  '[爱情]',
  '[衰]',
  '[撇嘴]',
  '[阴险]',
  '[奋斗]',
  '[发呆]',
  '[右哼哼]',
  '[抱抱]',
  '[坏笑]',
  '[飞吻]',
  '[鄙视]',
  '[晕]',
  '[大兵]',
  '[可怜]',
  '[强]',
  '[弱]',
  '[握手]',
  '[胜利]',
  '[抱拳]',
  '[凋谢]',
  '[米饭]',
  '[蛋糕]',
  '[西瓜]',
  '[啤酒]',
  '[瓢虫]',
  '[勾引]',
  '[OK]',
  '[爱你]',
  '[咖啡]',
  '[月亮]',
  '[刀]',
  '[发抖]',
  '[差劲]',
  '[拳头]',
  '[心碎了]',
  '[太阳]',
  '[礼物]',
  '[皮球]',
  '[骷髅]',
  '[挥手]',
  '[闪电]',
  '[饥饿]',
  '[咒骂]',
  '[折磨]',
  '[抠鼻]',
  '[鼓掌]',
  '[糗大了]',
  '[左哼哼]',
  '[打哈欠]',
  '[快哭了]',
  '[吓]',
  '[篮球]',
  '[乒乓]',
  '[NO]',
  '[跳跳]',
  '[怄火]',
  '[转圈]',
  '[磕头]',
  '[回头]',
  '[跳绳]',
  '[激动]',
  '[街舞]',
  '[献吻]',
  '[左太极]',
  '[右太极]',
  '[闭嘴]',
  '[猫咪]',
  '[红双喜]',
  '[鞭炮]',
  '[红灯笼]',
  '[麻将]',
  '[麦克风]',
  '[礼品袋]',
  '[信封]',
  '[象棋]',
  '[彩带]',
  '[蜡烛]',
  '[爆筋]',
  '[棒棒糖]',
  '[奶瓶]',
  '[面条]',
  '[香蕉]',
  '[飞机]',
  '[左车头]',
  '[车厢]',
  '[右车头]',
  '[多云]',
  '[下雨]',
  '[钞票]',
  '[熊猫]',
  '[灯泡]',
  '[风车]',
  '[闹钟]',
  '[雨伞]',
  '[彩球]',
  '[钻戒]',
  '[沙发]',
  '[纸巾]',
  '[手枪]',
  '[青蛙]',
];
///表情类
export const  expressionPath:any = {
  '微笑':'hehe.png',
  '撇嘴': 'piezui.png',
  '色': 'se.png',
  '发呆': 'fadai.png',
  '得意': 'deyi.png',
  '流泪': 'liulei.png',
  '害羞': 'haixiu.png',
  '闭嘴': 'bizui.png',
  '睡': 'shui.png',
  '大哭': 'daku.png',
  '尴尬': 'ganga.png',
  '发怒': 'fanu.png',
  '调皮': 'tiaopi.png',
  '呲牙': 'ciya.png',
  '惊讶': 'jingya.png',
  '难过': 'nanguo.png',
  '囧': 'jiong.png',
  '抓狂': 'zhuakuang.png',
  '吐': 'tu.png',
  '偷笑': 'touxiao.png',
  '愉快': 'yukuai.png',
  '白眼': 'baiyan.png',
  '傲慢': 'aoman.png',
  '困': 'kun.png',
  '惊恐': 'jingkong.png',
  '流汗': 'liuhan.png',
  '憨笑': 'hanxiao.png',
  '悠闲': 'youxian.png',
  '奋斗': 'fendou.png',
  '咒骂': 'zhouma.png',
  '疑问': 'yiwen.png',
  '嘘': 'xu.png',
  '晕': 'yun.png',
  '衰': 'sui.png',
  '骷髅': 'kulou.png',
  '敲打': 'qiaoda.png',
  '再见': 'zaininmadejian.png',
  '擦汗': 'cahan.png',
  '抠鼻': 'koubi.png',
  '鼓掌': 'guzhang.png',
  '坏笑': 'huaixiao.png',
  '左哼哼': 'zuohengheng.png',
  '右哼哼': 'youhengheng.png',
  '哈欠': 'haqian.png',
  '鄙视': 'bishi.png',
  '委屈': 'weiqu.png',
  '快哭了': 'kuaikule.png',
  '阴险': 'yinxian.png',
  '亲亲': 'qinqin.png',
  '可怜': 'kelian.png',
  '菜刀': 'caidao.png',
  '西瓜': 'xigua.png',
  '啤酒': 'pijiu.png',
  '咖啡': 'kafei.png',
  '猪头': 'zhutou.png',
  '玫瑰': 'meigui.png',
  '凋谢': 'diaoxie.png',
  '嘴唇': 'zuichun.png',
  '爱心': 'aixin.png',
  '心碎': 'xinsui.png',
  '蛋糕': 'dangao.png',
  '炸弹': 'zhadan.png',
  '便便': 'bianbian.png',
  '月亮': 'yueliang.png',
  '太阳': 'taiyang.png',
  '拥抱': 'yongbao.png',
  '强': 'qiang.png',
  '弱': 'ruo.png',
  '握手': 'woshou.png',
  '胜利': 'shengli.png',
  '抱拳': 'baoquan.png',
  '勾引': 'gouyin.png',
  '拳头': 'quantou.png',
  'OK': 'ok.png',
  '跳跳': 'tiaotiao.png',
  '发抖': 'fadou.png',
  '怄火': 'ohuo.png',
  '转圈': 'zhuanquan.png',
  '嘿哈': 'heiha.png',
  '捂脸': 'wulian.png',
  '奸笑': 'jianxiao.png',
  '机智': 'jizhi.png',
  '皱眉': 'zhoumei.png',
  '耶': 'ye.png',
  '蜡烛': 'lazhu.png',
  '红包': 'hongbao.png',
  '吃瓜': 'chigua.png',
  '加油': 'jiayou.png',
  '汗': 'han.png',
  '天啊': 'tiana.png',
  'Emm': 'emm.png',
  '社会社会': 'shehuishehui.png',
  '旺柴': 'wangchai.png',
  '好的': 'haode.png',
  '打脸': 'dalian.png',
  '加油加油': 'jiayoujiayou.png',
  '哇': 'wa.png',
  '發': 'fa.png',
  '福': 'fu.png',
  '鸡': 'ji.png',
}
///表情路径 - 不需要展示到表情面板，游戏结果发送的表情
export const hideExpressionPath:any = {
  '骰1点': 'hide_dice_1.png',
  '骰2点': 'hide_dice_2.png',
  '骰3点': 'hide_dice_3.png',
  '骰4点': 'hide_dice_4.png',
  '骰5点': 'hide_dice_5.png',
  '骰6点': 'hide_dice_6.png',
  '猜拳石头': 'hide_sjb_1.png',
  '猜拳剪刀': 'hide_sjb_2.png',
  '猜拳布': 'hide_sjb_3.png',
}
export const ALL_EXPRESSION = Object.assign({},expressionPath,hideExpressionPath)

export const faceUrl = 'https://web.sdk.qcloud.com/im/assets/face-elem/';

export const bigEmojiList:Array<any> = [
  {
    icon: 1,
    list: ['yz00', 'yz01', 'yz02', 'yz03', 'yz04', 'yz05', 'yz06', 'yz07', 'yz08',
      'yz09', 'yz10', 'yz11', 'yz12', 'yz13', 'yz14', 'yz15', 'yz16', 'yz17'],
  },
  {
    icon: 2,
    list: ['ys00', 'ys01', 'ys02', 'ys03', 'ys04', 'ys05', 'ys06', 'ys07', 'ys08',
      'ys09', 'ys10', 'ys11', 'ys12', 'ys13', 'ys14', 'ys15'],
  },
  {
    icon: 3,
    list: ['gcs00', 'gcs01', 'gcs02', 'gcs03', 'gcs04', 'gcs05', 'gcs06', 'gcs07',
      'gcs08', 'gcs09', 'gcs10', 'gcs11', 'gcs12', 'gcs13', 'gcs14', 'gcs15', 'gcs16'],
  },
];
