<template>
    <el-dialog
        custom-class='common_dialog dark'
        v-model='settingDialogVisible'
        :close-on-click-modal='true'
        :modal='true'
        :show-close='false'
        width='780px'>
        <template #header='{}'>
        </template>
        <div class='common_dialog_body flex'>
            <div class='setting_menu'>
                <div class='setting_menu_item' :class="{active:currentSettingMenu==='volume'}"
                     @click="handleClickSettingMenu('volume')">
                    <img src='@/assets/icons/volume2.png' alt=''>
                    <span>语音设置</span>
                </div>
                <div class='setting_menu_item' :class="{active:currentSettingMenu==='feedback'}"
                     @click="handleClickSettingMenu('feedback')">
                    <img src='@/assets/icons/edit.png' alt=''>
                    <span>问题反馈</span>
                </div>
            </div>
            <div class='setting_content'>
                <VoiceSetting v-show="currentSettingMenu === 'volume'" themes='dark' />
                <div v-show="currentSettingMenu === 'feedback'">
                    <div class='tab'>
                        <div :class='{tab_item:true,active:type===item.type}' v-for='(item,index) in tabList'
                             :key='index' @click='type=item.type'>
                            {{ item.name }}
                        </div>
                    </div>
                    <div class='title'>我的留言</div>
                    <div class='input'>
                        <el-input class='textarea'
                                  resize='none'
                                  v-model='describe'
                                  :rows='4'
                                  maxlength='150'
                                  type='textarea'
                                  placeholder='还想说点什么？'
                        />
                        <div class='pic'>
                            <div class='pic_item' v-for='(item,index) in imageUrlList' :key='index'>
                                <el-image class='image' :preview-src-list='imageUrlList' :initial-index='index'
                                          :src='item' alt=''>
                                </el-image>
                                <i class='icon icon-close' @click='closePic(index)'></i>
                            </div>
                            <el-upload
                                action='/dc/upload'
                                name='multipartFile'
                                v-model:file-list='fileList'
                                :show-file-list='false'
                                :before-upload='beforeUpload'
                                :on-success='successUpload'
                                :limit='3'
                            >
                                <div class='upIcon' v-if='imageUrlList.length<3'>
                                    <img class='icon' src='@/assets/icons/upIcon.png' alt=''>
                                    <div class='add'>添加图片</div>
                                </div>
                            </el-upload>
                        </div>
                    </div>
                    <div class='btnDisabled'
                         v-if="currentSettingMenu==='feedback'&&!describe&&!imageUrlList.length">提交
                    </div>
                    <div class='btn' v-if="currentSettingMenu==='feedback'&&(describe||imageUrlList.length)"
                         @click='submitFeedBack'>提交
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script setup>
import { ref } from 'vue'
import VoiceSetting from '@/components/VoiceSetting/index.vue'
import { ElMessage } from 'element-plus'
import { feedBack } from '@/api/room'

const settingDialogVisible = ref(false)
const openDialog = () => {
    settingDialogVisible.value = true
}
defineExpose({
    openDialog
})
const currentSettingMenu = ref('volume')
const handleClickSettingMenu = (item) => {
    if (item === 'volume') {
        window.nbilog.push('volumeSetting_click')
    } else {
        window.nbilog.push('problemFeedback_click')
    }
    currentSettingMenu.value = item
    // 根据菜单判断，显示不同的
}

const tabList = [{ name: '违规举报', type: 3 }, { name: '语聊相关', type: 1 }, { name: '操作体验', type: 2 }, {
    name: '功能建议',
    type: 4
}, { name: '崩溃闪退', type: 5 }, { name: '其他问题', type: 6 }]
const describe = ref('')
const type = ref(3)

const imageUrlList = ref([])
const beforeUpload = (file) => {
    const extension = file.name.split('.').pop().toLowerCase()
    if (!['png', 'jpg', 'jpeg'].includes(extension)) {
        ElMessage({
            type: 'error',
            message: '仅支持png,jpg和jpeg格式的文件'
        })
        return false
    }
    return true
}

const fileList = ref([])

const closePic = (index) => {
    imageUrlList.value.splice(index, 1)
    fileList.value.splice(index, 1)
}

const successUpload = (res) => {
    imageUrlList.value.push(res.data)
}

const submitFeedBack = () => {
    window.nbilog.push('feedbackSubmit_click')
    if (!describe.value && imageUrlList.value.length === 0) {
        ElMessage({
            type: 'warning',
            message: '请填写留言或添加图片',
            customClass: 'jyx_message'
        })
        return
    }
    feedBack({
        describe: describe.value,
        imageUrlList: imageUrlList.value.join(),
        type: type.value
    }).then((data) => {
        if (data.code === 0) {
            describe.value = ''
            imageUrlList.value = []
            type.value = 3
            ElMessage({
                type: 'success',
                message: '提交成功',
                customClass: 'jyx_message'
            })
            settingDialogVisible.value = false
        }
    })
}


</script>
<style lang='scss' scoped>
.common_dialog_body {
    padding: 0 !important;

    &.flex {
        display: flex;
    }

    .setting_menu {
        background-color: var(--main-bg-darker-color7);
        //width: 176px;
        padding: 50px 20px;
        box-sizing: border-box;

        .setting_menu_item {
            opacity: 0.56;
            padding: 16px 20px;
            border-radius: 6px;
            margin-bottom: 15px;
            cursor: pointer;
            color: #fff;
            display: flex;
            align-items: center;

            img {
                width: 14px;
                height: auto;
                margin-right: 6px;
            }

            &.active {
                background-color: var(--main-bg-darker-color6);
                opacity: 1;
            }
        }
    }

    .setting_content {
        flex: 1;
        padding: 50px 20px 30px 20px;

        .tab {
            display: flex;
            flex-wrap: wrap;

            &_item {
                width: 155px;
                height: 48px;
                border: 1px solid #4A4B5A;
                color: rgba(255, 255, 255, 0.7);
                border-radius: 6px;
                text-align: center;
                line-height: 48px;
                margin: 0 15px 15px 10px;
                cursor: pointer;
            }

            .active {
                background: rgba(67, 72, 117, 1);
                border: 1px solid rgba(105, 112, 190, 1);
            }
        }

        .title {
            margin: 10px 0 20px 10px;
            font-size: 15px;
            color: rgba(255, 255, 255, 0.66);
        }

        .input {
            width: 100%;
            height: 230px;
            background: rgba(32, 34, 51, 1);
            border-radius: 10px;

            .textarea {
                font-size: 15px;

                ::v-deep .el-textarea__inner {
                    border-radius: 0;
                    background: none;
                    border: none;
                    color: rgba(250, 250, 250, 0.7);
                    box-shadow: none;
                    padding: 20px;
                }
            }

            .pic {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .upIcon {
                    width: 73px;
                    height: 73px;
                    border-radius: 8px;
                    margin-left: 20px;
                    border: 1px dashed rgba(217, 217, 217, 0.17);
                    text-align: center;

                    .icon {
                        margin-top: 15px;
                    }

                    .add {
                        margin-top: 12px;
                        font-size: 13px;
                    }
                }

                .pic_item {
                    position: relative;

                    .icon {
                        position: absolute;
                        top: -12px;
                        right: -10px;
                        cursor: pointer;
                    }

                    .image {
                        width: 73px;
                        height: 73px;
                        border-radius: 8px;
                        margin-left: 20px;
                    }
                }

            }
        }

        .btn {
            width: 150px;
            height: 46px;
            border-radius: 5px;
            background: rgba(82, 88, 175, 1);
            text-align: center;
            line-height: 46px;
            font-size: 15px;
            color: white;
            cursor: pointer;
            margin: 34px auto 0;
        }

        .btnDisabled {
            width: 150px;
            height: 46px;
            border-radius: 5px;
            background: #3F415B;
            text-align: center;
            line-height: 46px;
            font-size: 15px;
            color: rgba(255, 255, 255, 0.43);
            margin: 34px auto 0;
        }
    }
}


</style>
