import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bae5aa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "el-icon-location-outline" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "message-location",
    href: _ctx.data.href,
    target: "_blank",
    title: "点击查看详情"
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.description), 1),
    _createElementVNode("img", {
      src: _ctx.data.url
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}