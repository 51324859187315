import { createStore } from 'vuex'

import Room from './modules/room'
import { fetchUserBasicInfo, loginOut } from '@/api/login'
import { useLocalStorage } from '@vueuse/core'

const getDefaultState = () => {
    return {
        loginState: false,
        networkStatus: true,
        isShowWarningTips: true,
        loginUserInfo: {
            hxId: '',
            nickname: '',
            avatarurl:
        'https://download-sdk.oss-cn-beijing.aliyuncs.com/downloads/IMDemo/avatar/Image5.png',
        },
        loginUserOnlineStatus: '',
        userBasicInfo:{},
        globalDialog:{
            dialogVisible:false,
            message:'',
            btnText:'知道了'
        },
        inputDeviceId:'',
        outputDeviceId:'',
        changedDevice:'',
        roomSeq:0,
        resetRoomInfo:false,
        currentChannel:{},
        quickphraseDialogVisible:false,
        refresh_quickphrase:false,
        refreshToken:'',
        enterloading:false
    }
}
export default createStore({
    state: getDefaultState(),
    getters: {
        userBasicInfo: (state) => state.userBasicInfo,
        loginUserInfo: (state) => state.loginUserInfo,
        loginUserOnlineStatus: (state) => state.loginUserOnlineStatus,
    },
    mutations: {
        RESET_STATE: (state) => {
            Object.assign(state, getDefaultState())
        },
        CLOSE_WARNING_TIPS: (state) => (state.isShowWarningTips = false),
        CHANGE_LOGIN_STATUS: (state, status) => {
            state.loginState = status
        },
        CHANGE_NETWORK_STATUS: (state, status) => {
            state.networkStatus = status
        },

        SET_LOGIN_USER_INFO: (state, infos) => {
            state.loginUserInfo = Object.assign(state.loginUserInfo, infos)
        },

        SET_LOGIN_USER_ONLINE_STATUS: (state, payload) => {
            console.log('payload', payload)
            state.loginUserOnlineStatus = payload
        },
        SET_USER_BASIC_INFO: (state, infos) => {
            state.userBasicInfo = Object.assign(state.userBasicInfo, infos)
        },
        OPEN_GLOBALDIALOG:(state,obj)=>{
            state.globalDialog = obj
        },
        CLOSE_GLOBALDIALOG:(state)=>{
            state.globalDialog.dialogVisible = false
        },
        SET_INPUTDEVICEID:(state,newId)=>{
            state.inputDeviceId = newId
        },
        SET_OUTPUTDEVICEID:(state,newId)=>{
            state.outputDeviceId = newId
        },
        SET_ROOMSEQ:(state,seq)=>{
            state.roomSeq = seq
        },
        SET_RESETROOMINFO:(state,res)=>{
            state.resetRoomInfo = res
        },
        CHANGE_INPUTDEVICEID:(state,newId)=>{
            state.changedDevice = newId
        },
        SET_CURRENTCHANNEL:(state,channel)=>{
            state.currentChannel = channel
        },
        SHOWQUICKPHRASE_DIALOG:(state,visible)=>{
            window.nbilog.push(visible?'openSpeedManage_click':'closeSpeedManage_click')
            state.quickphraseDialogVisible = visible
        },
        REFRESH_QUICKPHRASE:(state,enbale)=>{
            state.refresh_quickphrase = enbale
        },
        SET_REFRESHTOKEN:(state,token)=>{
            state.refreshToken = token
        },
        SET_ENTERLOADING:(state,loading)=>{
            state.enterloading = loading
        }
    },
    actions: {
        // 退出登录
        userLogout: async ({ commit }) => {
            const res = await loginOut()
            console.log('退出登录',res)
            commit('RESET_STATE')
        },
        //获取用户的基本信息
        getUserBasicInfo: async ({ commit }) => {
            const userInfo = await fetchUserBasicInfo()
            console.log('得到用户的基本信息',userInfo)
            if(userInfo && userInfo.data){
                commit('SET_USER_BASIC_INFO', userInfo.data)
                return Promise.resolve()
            }
            return Promise.reject()
        },
        resetToken: async ({ commit }) => {
            commit('RESET_STATE')
            const loginUserFromStorage = useLocalStorage('loginUser', {})
            loginUserFromStorage.value = {}
        }
        //获取登陆用户的用户属性
        // getMyUserInfo: async ({ commit }, userId) => {
        //     const { data } = await EaseChatClient.fetchUserInfoById(userId)
        //     data[userId].hxId = userId
        //     commit('SET_LOGIN_USER_INFO', data[userId])
        // },
        // //修改登陆用户的用户属性
        // updateMyUserInfo: async ({ commit }, params) => {
        //     const { data } = await EaseChatClient.updateUserInfo({ ...params })
        //     console.log('>>>>>>修改成功', data)
        //     commit('SET_LOGIN_USER_INFO', data)
        // },
        //处理在线状态订阅变更（包含他人的用户状态）
        // handlePresenceChanges: ({ commit }, status) => {
        //     const { userId, ext: statusType } = status || {}
        //     if (userId === EaseChatClient.user) {
        //         commit(
        //             'SET_LOGIN_USER_ONLINE_STATUS',
        //             statusType ? statusType : 'Unset'
        //         )
        //     } else {
        //         console.log('>>>>>>不是自己的状态')

        //         commit('SET_FRIEND_PRESENCE', [{ ...status }])
        //     }
        // },
    },
    modules: {
        // Conversation,
        // Contacts,
        // Message,
        // Groups,
        Room
    },
})
