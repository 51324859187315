import { useStorage } from '@vueuse/core'
import { Base64 } from '@/utils/base64'
export function getBrowserVersion(browserType, UserAgent) {
    let versions = ''

    switch (browserType) {
    case 'IE':
        versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2]
        break
    case 'Chrome':
        for (const mt in navigator.mimeTypes) {
            //360 pc      
            if (navigator.mimeTypes[mt]['type'] == 'application/360softmgrplugin') {
                browserType = '360'
            }
        }
        versions = UserAgent.match(/chrome\/([\d.]+)/)[1]
        break

    case 'Firefox':
        versions = UserAgent.match(/firefox\/([\d.]+)/)[1]
        break
    case 'Opera':
        versions = UserAgent.match(/opera\/([\d.]+)/)[1]
        break
    case 'Safari':
        versions = UserAgent.match(/version\/([\d.]+)/)[1]
        break
    case 'Edge':
        versions = UserAgent.match(/edge\/([\d.]+)/)[1]
        break
    case 'QQBrowser':
        versions = UserAgent.match(/qqbrowser\/([\d.]+)/)[1]
        break
    }

    return parseInt(versions)
}
export function getBrowser() {
    const UserAgent = navigator.userAgent.toLowerCase()
    const browserInfo = {}

    const browserObj = {
        IE: window.ActiveXObject || 'ActiveXObject' in window,
        Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1,
        Firefox: UserAgent.indexOf('firefox') > -1,
        Opera: UserAgent.indexOf('opera') > -1,
        Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1,
        Edge: UserAgent.indexOf('edge') > -1,
        QQBrowser: /qqbrowser/.test(UserAgent),
        WeixinBrowser: /MicroMessenger/i.test(UserAgent)
    }

    for (const key in browserObj) {
        if (Object.prototype.hasOwnProperty.call(browserObj, key) && browserObj[key]) {
            browserInfo.browser = key
            browserInfo.versions = getBrowserVersion(key, UserAgent)
            break
        }
    }

    return browserInfo
}
export function setCookieEncode (key, val, time) {
    if (typeof key !== 'string' || typeof val !== 'string') {
        return false
    }
    time = time || 360 * 24 * 3600
    var exp = new Date()
    exp.setTime(exp.getTime() + time * 1000)
    document.cookie = key + '=' + encodeURIComponent(val) + ';expires=' + exp.toGMTString()
}
// 通过key来获取cookie，本方法依赖于getAllCookies()
// getCookieByKey (key) {
//   return this.getAllCookies()[key]
// },
export function getCookieByKey (key) {
    var name = key + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}
  
// 通过key来获取cookie，本方法依赖于getAllCookies()
// getCookieByKey (key) {
//   return this.getAllCookies()[key]
// },
export function getCookieByKeyDecode (key) {
    var name = key + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return decodeURIComponent(c.substring(name.length, c.length))
        }
    }
    return ''
}
export function makeActionMap(action, id) {
    var map = {
        role: 1,
        source: 1,
        dispatcherType: 1,
        gameDetailsDTO: {
            action
        }
    }
    if (id) {
        map['sendUserId'] = id
        map.dispatcherType = 2
    }
    return map
}
export function isDir (type) {
    return !type.file
}
export function isPubClue(type) {
    return type.iden === 10 || type.iden === 14
}
export function  isClue (type)  {
    return type.iden === 7 || type.iden === 10 || type.iden === 15 || type.iden === 14
}
export function  isScript (type) {
    return type.iden === 8 || type.iden === 9 || type.iden === 16 || type.iden === 17
}
export function  isPubScript (type) {
    return type.iden === 16 || type.iden === 17
}


/**
   * 新bilog方法
   * @param {int} id 活动id，产品提供
   * @param {object} oExtend 扩展参数对象, open - 初始化时，是否打点 true，默认打
   * @param {string} action_name 固定操作1：open-页面展示、login_success-登陆成功(无需登陆的活动可以不打)、join_activity_success-参加活动成功（产品定义）、ad_init_success-广告开始缓存
   * @param {string} action_name 固定操作2：ad_init_fail-广告缓存失败、ad_click-广告开始加载（播放）、ad_show_success-广告展示成功、ad_show_fail-广告展示终止
   * @param {string} action_name 自定义操作：除了上述定义好的固定操作，其余操作自己定义action_name；建议规则：act_{activity_id}_{自定义名}
   * @param {string} json_content 操作内容json字符串，备用字段
   * @param {string} jsonExtend 打点所需的参数，例如：userChannel、viewChannel
*/
export function NBilog(id, oExtend, jsonExtend) {
    // const defalutOExtend = { open: true, hasBaidu: false }
    const defalutOExtend = { open: true }
    if (typeof oExtend === 'object') {
        oExtend = Object.assign({}, defalutOExtend, oExtend)
    } else {
        oExtend = defalutOExtend
    }
    try {
        if (!id) {
            return false
        }
        this.id = id
        this.isBaidu = typeof window.BaiDuMobileGameJsBridge === 'object'
        this.isCoolclub = typeof window.coolclubJsbridge === 'object'
        this.arrFixActionName = ['open', 'login_success', 'join_activity_success', 'join_activity_error', 'ad_init_success', 'ad_cache_success', 'ad_init_fail', 'ad_click', 'ad_show_success', 'ad_show_fail']
        // this.preActionName = `act_${this.id}_`
        this.hostname = process.env.NODE_ENV === 'dev' ? '//state.duoku.com/kklog-qa?' : '//state.duoku.com/kklog?'
        this.action = 'juyouxi_pc'
        this.isOpen = false
        this.jsonExtend = jsonExtend || {}
        oExtend.open && this.push('open')
    // eslint-disable-next-line no-empty
    } catch (ex) {}
}
NBilog.prototype.push = function (actionName, jsonRemark = {}) {
    try {
        if (actionName === 'open') {
            if (this.isOpen) {
                return false
            }
            this.isOpen = true
        }
        // 全局将房间相关西悉尼打点
        const roomInfoRes = JSON.parse(localStorage.getItem('roomInfoRes'))
        let roomContent = {}
        if (roomInfoRes) {
            roomContent = {
                rmid: roomInfoRes.id,
                dmid: roomInfoRes.dmId,
                jbid: roomInfoRes.dramaId,
                jbname: roomInfoRes.dramaInfoRes.dramaName,
                jstype: 2 // 角色类型DM传2
            }
        }
        const jsonContent = Object.assign({}, jsonRemark, roomContent, this.jsonExtend, { activity_id: this.id })
        
        // 自定义的actionName，如果不在固定操作内，且没有act_${id}前缀，统一增加前缀
        // if (this.arrFixActionName.indexOf(actionName) === -1 && actionName.indexOf(this.preActionName) === -1) {
        //     actionName = this.preActionName + actionName
        // }
        const loginUserFromStorage = useStorage('loginUser')
        const agoraUserId = JSON.parse(loginUserFromStorage.value || '').userId
        // debugger
        const objParams = Object.assign({
            logtime: (new Date()).getTime(),
            action_name: actionName,
            userid: agoraUserId || '',
            deviceid: guid(),
            phone: getEnvInfo(),
            json_content: JSON.stringify(jsonContent)
        }, this.baseInfo, this.appInfo)
        const oImg = new Image()
        oImg.src = this.hostname + `action=${this.action}&data=` +  Base64.encode(JSON.stringify(objParams))
    // eslint-disable-next-line no-empty
    } catch (ex) {}
}
export function UrlSearch (url) {
    var name = ''
    var value = ''
    var str = url // 取得整个地址栏
    var num = str.indexOf('?')
    var searchObj = null
    str = str.substr(num + 1) // 取得所有参数stringvar.substr(start [, length ]
    if (str) {
        searchObj = {}
    }
    var arr = str.split('&') // 各个参数放到数组里
    for (var i = 0; i < arr.length; i++) {
        num = arr[i].indexOf('=')
        if (num > 0) {
            name = arr[i].substring(0, num)
            if (name === 'roomId') {
                name = 'rmid'
            }
            value = arr[i].substr(num + 1)
            searchObj[name] = value
        }
    }
    return searchObj
}
/**
 *获取UUID
*/
function guid () {
    if (localStorage.getItem('UUID')) {
        return localStorage.getItem('UUID')
    } else {
        const UUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0
            const v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
        localStorage.setItem('UUID', UUID)
        return UUID
    }
}
// 获取浏览器品牌
export function getExploreName() {
    var userAgent = navigator.userAgent
    if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        return 'Opera'
    } else if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1) {
        return 'IE'
    } else if (userAgent.indexOf('Edge') > -1) {
        return 'Edge'
    } else if (userAgent.indexOf('Firefox') > -1) {
        return 'Firefox'
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
        return 'Safari'
    } else if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) {
        return 'Chrome'
    } else if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return 'IE>=11'
    } else {
        return 'Unkonwn'
    }
}
// 获取App信息
export function getAppInfo() {
    const ua = navigator.userAgent.toLowerCase()
    const isWeiXin = ua.match(/MicroMessenger/i) === 'micromessenger'
    const isQQ = ua.match(/QQ/i) === 'qq'
    if (isWeiXin) {
        return 'weiXin'
    } else if (isQQ) {
        return 'QQ'
    } else {
        return 'browser'
    }
}
  
// 获取系统信息
export function getSystemInfo() {
    var plat = navigator.platform
    var ua = navigator.userAgent
    if (plat.indexOf('Win') === 0) {
        return 'Win'
    } else if (plat.indexOf('Mac') === 0) {
        return 'Mac'
    } else if (plat.indexOf('iPad') === 0) {
        return 'iPad'
    } else if (plat.indexOf('iPhone') === 0) {
        return 'iPhone'
    } else if (ua.indexOf('Android') > -1) {
        return 'Android'
    } else {
        return 'Unknown'
    }
}
  
// 获取环境信息
export function getEnvInfo() {
    return `${getAppInfo()}|${getSystemInfo()}|${getExploreName()}`
}
export function getQueryVariable (variable) {
    var query = window.location.search.substring(1)
    console.log('query--------',window.location, query)

    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
            return pair[1]
        }
    }
    return (false)
}
export function getQueryBaseObj () {
    const oParams = {}
    let query = ''
    const urlE = getQueryVariable('e')
    console.log('urlE--------',urlE)
    if (urlE) {
        query = Base64.decode(urlE)
    }

    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        oParams[pair[0]] = pair[1]
    }
    // console.log(oParams)
    return oParams
}
export function getTrueVolume(volume) {
    if(volume <= 50){
        return Math.floor(volume/5) + 1
    }else{
        return volume - 40
    }
}


// 判断浏览器函数
export function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true // 移动端
    } else {
        return false // PC端
    }
}
  
// 判断浏览器函数
export function isApp() {
    // alert(111)
    // alert(window.PublicChatBridge)
    // alert(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getLoginToken)
    // console.log(window.JSBridge)
    if (window.PublicChatBridge || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getLoginToken)) {
        return true // app
    } else {
        return false // PC端
    }
}
// 判断浏览器函数
export function isIpad() {
    var ua = navigator.userAgent.toLowerCase()
    if (/ipad/i.test(ua)) {
        return true
    } else {
        return false
    }
}