<template>
  <div class="face" id="face" >
    <img src="@/assets/icons/emoji.png" class="face_icon" alt="" @click="toggleShow">
    <main class="face-main-group" :class="[isH5 && 'face-H5-main']" v-show="show" ref="dialog">
      <ul class="face-list">
        <li
          class="face-list-item"
          v-for="(childrenItem, key) in expressionPath"
          :key="key"
          @click="select(childrenItem, key)"
        >
          <img :src="require(`@/assets/images/expression/${childrenItem}`)" />
        </li>
      </ul>
      <!-- <ul class="face-tab">
        <li class="face-tab-item" @click="selectFace(0)">
          <i class="icon icon-face"></i>
        </li>
        <li class="face-tab-item" v-for="(item, index) in bigEmojiList" :key="index" @click="selectFace(index + 1)">
          <img :src="faceUrl + item.list[0] + '@2x.png'" />
        </li>
      </ul> -->
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watchEffect, toRefs, computed, ref } from 'vue';
import { emojiUrl, emojiMap, emojiName, faceUrl, bigEmojiList,expressionPath,hideExpressionPath } from '../../utils/emojiMap';
import { onClickOutside } from '@vueuse/core';
import { handleErrorPrompts } from '../../../utils';

const Face = defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    parentID: {
      type: String,
      default: () => '',
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      emojiUrl,
      emojiMap,
      emojiName,
      faceUrl,
      bigEmojiList:(window as any)?.TUIKitTUICore?.isOfficial && bigEmojiList || [],
      show: false,
      currentIndex: 0,
      isMute: false,
      transDom: false,
      expressionPath
    });

    const dialog: any = ref();

    watchEffect(() => {
      data.show = props.show;
      data.isMute = props.isMute;
    });

    const toggleShow = () => {
      const main: any = document.getElementsByClassName('face-main-group')[0];
      if (!data.isMute) {
        main.style.display = main.style.display === 'none' ? 'flex' : 'none';
      }
      if (main.style.display === 'none') {
        selectFace(0);
      }
      toggleH5Show();
    };

    const toggleH5Show = () => {
      const parent = document.getElementById(props.parentID);
      const main = document.getElementsByClassName('face-H5-main')[0];
      if (props.isH5) {
        parent?.appendChild(main);
      }
    };

    onClickOutside(dialog, () => {
      const main: any = document.getElementsByClassName('face-main-group')[0];
      if (main) {
        main.style.display = 'none';
      }
    });

    const select = async (item: string, key: any) => {
      const options: any = {
        name: '['+key+']',
      };
      if (data.currentIndex === 0) {
        options.type = 'emo';
        options.url = require(`@/assets/images/expression/${item}`);
        options.template = `<img src="${require('@/assets/images/expression/'+item)}">`;
        if (!props.isH5) {
          toggleShow();
        }
        return ctx.emit('send', options);
      }
      // try {
      //   await Face.TUIServer.sendFaceMessage({
      //     // Change large expression display field
      //     index: data.bigEmojiList[data.currentIndex - 1].icon,
      //     data: data.bigEmojiList[data.currentIndex - 1].list[index],
      //   });
      // } catch (error) {
      //   handleErrorPrompts(error, props);
      // }
      if (!props.isH5) {
        toggleShow();
      }
    };

    const list = computed(() => {
      const emojiList = [data.emojiName];
      for (let i = 0; i < data.bigEmojiList.length; i++) {
        emojiList.push(data.bigEmojiList[i].list);
      }
      return emojiList;
    });

    const selectFace = (index: number) => {
      data.currentIndex = index;
    };

    return {
      ...toRefs(data),
      toggleShow,
      select,
      selectFace,
      list,
      dialog,
    };
  },
});
export default Face;
</script>

<style lang="scss" scoped src="./style/index.scss"></style>
