import store from '@/store'
import { request, postTokenCancle } from '@/utils/request'

//进入房间
export function enterRoom(roomId) {
    return request({
        url: `/dc/chat/pc/enter/${roomId}`,
        method: 'post'
    })
}

//退出房间
export function exitRoom(data, roomId) {
    return request({
        url: `/dc/chat/pc/exit/${roomId}`,
        method: 'post',
        data
    })
}


// 获取剧本最新价格
export function getNewPrice(params) {
    return request({
        url: '/dc/pay/pc/drama',
        method: 'get',
        params
    })
}

//正版剧本支付
export function payDrama(data) {
    return request({
        url: '/dc/pay/pc/order/save',
        method: 'post',
        data
    })
}

//获取房间所有数据
export function getRoomAlldata(roomId) {
    return request({
        url: `/dc/chat/pc/getAllData/${roomId}`,
        method: 'post'
    })
}

//获取房间信息
export function getMsgList(roomId) {
    return request({
        url: `/dc/chat/pc/getMsgListV3/${roomId}`,
        method: 'post'
    })
}

//获取房间心跳
export function getRoomHeart(roomId) {
    return request({
        url: `/dc/chat/pc/heart/${roomId}`,
        method: 'post'
    })
}

//获取房间心跳
export function sendMsg(data, roomId) {

    return new Promise((resolve, reject) => {
        request({
            url: `/dc/chat/pc/sendMsgV3/${roomId}`,
            method: 'post',
            data
        }).then(res => {
            console.log('得到sendMsg返回De结果', res)
            if (res.data && res.data.length) {
                const roomSeq = store.state.roomSeq
                const lastData = res.data[res.data.length - 1]
                console.log('得到seq前后', roomSeq, lastData.seq)

                // 处理序列，如果消息序列不连续，说明丢了消息，需要重新获取房间信息
                if (roomSeq > 1 && lastData.seq - roomSeq > 1) {
                    console.log('消息序列不连续,重新获取房间信息')
                    store.commit('SET_RESETROOMINFO', true)
                    reject({ reconnect: 1 })
                } else {
                    store.commit('SET_ROOMSEQ', lastData.seq)
                    resolve(res)
                }
            } else {
                resolve(res)
            }


        }).catch(err => {
            console.log('sendMsg------catch', err)
            reject(err)
        })


    })

}

//获取剧本线索
export function getChapterList(roomId) {
    return request({
        url: `/dc/chat/pc/getChapterList/${roomId}`,
        method: 'post',
        data: {
            roomId
        }
    })
}

//房间设置 修改房间信息
export function updateRoomInfo(data) {
    return request({
        url: '/dc/pc/room/immediate/opening/update',
        method: 'post',
        data
    })
}

//获取dm手册内容
export function getDMScriptContent(id) {
    return request({
        url: `/dc/df/dm-manual/content/${id}`,
        method: 'get'
    })
}

/****
 * 普通玩家获取剧本内容
 */
export function getScriptContent(params) {
    return request({
        url: '/drama/chapter/role-drama/content02/',
        method: 'get',
        params
    })
}

/****
 * DM获取普通玩家获取已发放内容
 */
export function unlockRoleList(params, roleId, roomId, source) {
    const url = `/dc/chat/df/drama/alluserUnLockList/${roomId}/${roleId}`
    return postTokenCancle(url, params, source)
}

export function chatGetAllClueList(params, dramaid, roomId) {
    return request({
        url: `/dc/chat/pc/clue/all/list/036/${dramaid}/${roomId}`,
        method: 'get',
        params
    })
}

//获取所有解锁剧本线索
export function chatGetAllUnlockRes(data, roomId) {
    return request({
        url: `/dc/chat/pc/getAllUnlockRes/${roomId}`,
        method: 'post',
        data
    })
}

// 视频转换
export function sign(params) {
    const url = '/drama/music/sign'
    return request({
        url,
        method: 'get',
        params
    })
}

export function feedBack(params) {
    const url = '/dc/pc/user/feedback'
    return request({
        url,
        method: 'post',
        params
    })
}

export function cancelFocusOn(focusOnUserId) {
    const url = `/dc/pc/user/cancelFocusOn/${focusOnUserId}`
    return request({
        url,
        method: 'get'
    })
}

export function focusOn(focusOnUserId) {
    const url = `/dc/pc/user/focusOn/${focusOnUserId}`
    return request({
        url,
        method: 'get'
    })
}

export function isFocusOn(focusOnUserId) {
    const url = `/dc/pc/user/isFocusOn/${focusOnUserId}`
    return request({
        url,
        method: 'get'
    })
}

export function playMusic(data) {
    const url = '/dc/chat/ms/play'
    return request({
        url,
        method: 'post',
        data
    })
}

export function updateMusic(data) {
    const url = '/dc/chat/ms/play/update'
    return request({
        url,
        method: 'post',
        data
    })
}

export function queryMusic(roomId, rtcChannel) {
    const url = `/dc/chat/ms/play/${roomId}/${rtcChannel}`
    return request({
        url,
        method: 'get'
    })
}

export function AddQuickPhrase(data) {
    const url = '/dc/pc/user/quickPhrase/add'
    return request({
        url,
        method: 'post',
        data
    })
}

export function deleteQuickPhrase(data) {
    const url = '/dc/pc/user/quickPhrase/delete'
    return request({
        url,
        method: 'post',
        data
    })
}

export function getQuickPhraseList(data) {
    const url = '/dc/pc/user/quickPhrase/list'
    return request({
        url,
        method: 'post',
        data
    })
}

export function updateQuickPhrase(data) {
    const url = '/dc/pc/user/quickPhrase/update'
    return request({
        url,
        method: 'post',
        data
    })
}
