import {request} from '@/utils/request'

//获取用户登陆token
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login',
//         method: 'post',
//         data: params
//     })
// }

//新获取用户登录token
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login/V1',
//         method: 'post',
//         data: params
//     })
// }
//新获取用户登录token v2
export function fetchUserLoginToken(params) {
    return request({
        url: '/dc/login/pc/pcclogin',
        method: 'post',
        data: params
    })
}
//新获取短信验证码
export function fetchUserLoginSmsCode(phoneNumber) {
    return request({
        url: `/dc/api/sms/sendCode?phone=${phoneNumber}`,
        method: 'get'
    })
}
// 退出登录
export function loginOut() {
    return request({
        url: '/dc/login/pc/pccloginOut',
        method: 'get'
    })
}
// 获取基本信息
export function fetchUserBasicInfo() {
    return request({
        url: '/dc/pc/user/getUserHomeBasicInfo',
        method: 'get'
    })
}