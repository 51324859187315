<script setup>
import { ref } from 'vue'
// import { EaseChatClient } from '@/IM/initwebsdk'
import store from '@/store'
import router from '@/router'
const dialogVisible = ref(false)
const isClearStorage = ref(true)

const logoutTheUser = async() => {
    if (isClearStorage.value) {
        console.log('>>>>>选择清空本地缓存')
        clearLocalStorage()
    }
    dialogVisible.value = false
    // EaseChatClient.close()
    await  store.dispatch('userLogout')
    // router.push(`/login?redirect=${this.$route.fullPath}`)
}

const clearLocalStorage = () => {
    const storageType = ['INFORM', 'conversationList', 'search_hisory','group_messageList']
    // const loginUserId = EaseChatClient.user
    // const storageKey = `EASEIM_${loginUserId}`
    // storageType.map((item) => {
    //     // return window.localStorage.removeItem(`${storageKey}_${item}`)
    // })
    window.localStorage.removeItem('loginUser')
}
defineExpose({
    dialogVisible
})
</script>
<template>
    <el-dialog v-model="dialogVisible" custom-class="common_dialog dark noHeader" width="441px">
        <div class="common_dialog_header">
            <div class="common_dialog_title">退出登录</div>
            <img src="@/assets/icons/close.png" alt="" class="common_dialog_close" @click="dialogVisible=false">
        </div>
        <div class="common_dialog_body">
            <div class="warning_content">
                确认退出当前登录账号？
            </div>
            <div class='common_dialog_actions flex'>
                <div class='common_dialog_action_btn cancel marginRight' @click="dialogVisible = false">
                    取消
                </div>
                <div class='common_dialog_action_btn confirm' @click='logoutTheUser'>确定</div>
            </div>
        </div>
    </el-dialog>
</template>



<style lang="scss" scoped>
.common_dialog_body {
    display: flex;
    align-items: center;
    flex-direction: column;

    .warning_content {
        font-size: 15px;
        margin: 40px auto;
        color: rgba($color: #fff, $alpha: 0.6);
    }
    .common_dialog_actions {
        padding-bottom: 15px;
    }
}
</style>