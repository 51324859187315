import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { TUIComponents, TUICore } from './TUIKit'
import ElementPlus from 'element-plus'
import './styles/element/index.scss'
import './styles/themes/index.css'

import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/icons'  // 导入图标资源
import SvgIcon from '@/components/SvgIcon/index.vue'// svg component
import 'viewerjs/dist/viewer.css'
import ImgViewer from '@/components/ImgViewer/index.vue'

let SDKAppID = 1400826961 // Your SDKAppID
let secretKey = 'e39c282a723a8218cb39e1f984655b662a51b2f60c629b2583e2106372703f1b' // Your secretKey

if (process.env.NODE_ENV === 'dev') { // dev
    SDKAppID = 1400826961 // Your SDKAppID
    secretKey = 'e39c282a723a8218cb39e1f984655b662a51b2f60c629b2583e2106372703f1b' // Your secretKey
} else if (process.env.NODE_ENV === 'qa') { // qa
    SDKAppID = 1400826959 // Your SDKAppID
    secretKey = '7081a77a2afd048c8d4dd03b8c35eecc34417f8524e5d9cad26d187888fa49c4' // Your secretKey
} else { // 线上
    SDKAppID = 1400825594 // Your SDKAppID
    secretKey = '968c69ea547a1a505202b7486d5cddea659bf7f555413cfa58898518db037e9b' // Your secretKey
}
const TUIKit = TUICore.init({
    SDKAppID
})
TUIKit.tim.setLogLevel(3)

TUIKit.use(TUIComponents)

createApp(App)
    .use(store)
    .use(router)
    .use(TUIKit)
    .component('svg-icon', SvgIcon)
    .use(ElementPlus, { locale: zhCn })
    .component('img-viewer',ImgViewer)
    .mount('#app')
export { SDKAppID, secretKey }