import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-771b5456"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "revoke" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.message.flow === 'in')
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.message.nick || _ctx.message.from), 1))
      : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.$t('TUIChatGroup.您')), 1)),
    _createElementVNode("span", null, _toDisplayString(_ctx.$t("TUIChatGroup.撤回了一条消息")), 1),
    (_ctx.message.flow === 'out'&&_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "edit",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)))
        }, _toDisplayString(_ctx.$t('TUIChatGroup.重新编辑')), 1))
      : _createCommentVNode("", true)
  ]))
}