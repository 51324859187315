<template>
    <div class='quickphrases' id='quickphrases'>
        <img src='@/assets/icons/chat2.png' class='quickphrase_icon' alt='' @click='toggleShow'>
        <main class='quickphrase-main-group' :class="[isH5 && 'quickphrase-H5-main']" v-show='show'
              ref='quickphraseTip'>
            <div class='quickphrase_header'>
                <div class='quickphrase_header_left'>
                    <div class='label'>常用语</div>
                </div>
                <div class='quickphrase_header_right'>
                    <div class='quickphrase_action_item' @click='showquickphraseDialog'>
                        <img src='@/assets/icons/guanli.png' alt=''>
                    </div>
                </div>
            </div>
            <div class='quickphrase_main' v-loading='listLoading' element-loading-text=''
                 element-loading-background='rgba(0,0,0,0.1)'>
                <el-scrollbar class='quickphrase_list' v-if='quickphraseList.length'>
                    <div class='quickphrase_list_item' v-for='(item, index) in quickphraseList' :key='index'
                         @click='selectQuickPhrase(item)'>
                        {{ item.phrase }}
                    </div>
                </el-scrollbar>
                <div class='quickphrase_empty' v-else>
                    暂无快捷用语
                </div>
            </div>
            <div class='arrow'></div>
        </main>
    </div>
</template>
<script lang='ts'>
import { defineComponent, reactive, watchEffect, onMounted, watch, toRefs, computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { getQuickPhraseList } from '@/api/room'
import { useStore } from 'vuex'

const QuickPhase = defineComponent({
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        isH5: {
            type: Boolean,
            default: () => false
        }
    },
    setup(props: any, ctx: any) {
        const store = useStore()
        const data = reactive({
            show: false,
            quickphraseList: [],
            listLoading: false
        })

        const quickphraseTip: any = ref()

        watchEffect(() => {
            data.show = props.show
        })

        const toggleShow = () => {
            const main: any = document.getElementsByClassName('quickphrase-main-group')[0]
            main.style.display = main.style.display === 'none' ? 'flex' : 'none'
        }

        const toggleH5Show = () => {
            const parent = document.getElementById(props.parentID)
            const main = document.getElementsByClassName('quickphrase-H5-main')[0]
            if (props.isH5) {
                parent?.appendChild(main)
            }
        }

        onClickOutside(quickphraseTip, () => {
            const main: any = document.getElementsByClassName('quickphrase-main-group')[0]
            if (main) {
                main.style.display = 'none'
            }
        })
        const selectQuickPhrase = (item: any) => {
            ctx.emit('sendQuickPhrase', item.phrase)
            toggleShow()
        }
        const showquickphraseDialog = () => {
            store.commit('SHOWQUICKPHRASE_DIALOG', true)
            toggleShow()
        }
        watch(() => store.state.refresh_quickphrase,
            (newVal) => {
                if (newVal) {
                    console.log('执行更新快捷用语')
                    getQuickPhraseListData()
                }
            })
        const getQuickPhraseListData = () => {
            data.listLoading = true
            getQuickPhraseList().then(res => {
                console.log('得到快捷用语的结果', res)
                data.quickphraseList = res.data.phraseList
            }).finally(() => {
                data.listLoading = false
                store.commit('REFRESH_QUICKPHRASE', false)
            })
        }
        onMounted(() => {
            getQuickPhraseListData()
        })


        return {
            ...toRefs(data),
            toggleShow,
            selectQuickPhrase,
            showquickphraseDialog,
            quickphraseTip
        }
    }
})
export default QuickPhase
</script>
<style lang='scss' scoped>
.quickphrases {
    display: inline-block;
    position: relative;

    .quickphrase_icon {
        width: 20px;
        height: 20px;
        margin: 0px 10px 1px 12px;
        display: block;
        opacity: 0.5;
        cursor: pointer;
    }

    .quickphrase-main-group {
        position: fixed;
        z-index: 5;
        width: 360px;
        height: 210px;
        bottom: 144px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        left: 80px;
        background: #22243C;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        color: #fff;

        .quickphrase_header {
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: #FFFFFF;
            font-size: 13px;
            margin-bottom: 8px;

            .quickphrase_header_left {
                flex: 1;

                .label {
                    height: 25px;
                    padding: 0 6px;
                    background-color: #6169DC;
                    display: inline-block;
                    border-radius: 10px;
                    line-height: 28px;
                }
            }

            .quickphrase_header_right {
                .quickphrase_action_item {
                    cursor: pointer;

                    img {
                        width: 16px;
                    }
                }
            }
        }

        .quickphrase_main {
            height: calc(100% - 30px);
        }

        .quickphrase_list {
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.60);

            .quickphrase_list_item {
                padding: 12px 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-bottom: 1px solid rgba(81, 86, 122, 0.53);
                cursor: pointer;
                background: #22243C;

            }

            .quickphrase_list_item:hover {
                color: #A9AFFF;
                background: linear-gradient(270deg, rgba(74, 77, 115, 0.00) -0.75%, rgba(73, 77, 115, 0.23) 10.92%, rgba(72, 76, 115, 0.70) 71.43%, rgba(72, 76, 115, 0.00) 98.67%);
            }
        }


        .quickphrase_empty{
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.60);
        }

        .arrow{
            width: 55px;
            height: 20px;
            background: #22243C;
            position: absolute;
            bottom: 5px;
            left: 11px;
            border-radius: 7px;
            rotate: -45deg;
            z-index: -1;
        }
    }
}
</style>
