<template>
    <el-dialog v-model="visible" custom-class="common_dialog dark noHeader" :close-on-click-modal="true" :modal="true">
        <div class="create-dialog">
            <div class="common_dialog_header">
                <div class="common_dialog_title">{{ videoObj.name || videoObj.title }}</div>
                <img src="@/assets/icons/close.png" alt="" class="common_dialog_close" @click="closeVideo">
            </div>
            <div class="videoCon" v-if="visible">
                <video controls="controls" autoplay="autoplay" id="video" class="video" v-if="VideoUrl"
                    :poster="videoObj.coverUrl"
                    :controlslist="videoObj.sc == 3 ? 'nodownload nofullscreen noremoteplayback' : ''">
                    <source :src="VideoUrl">
                </video>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { toRefs, ref, watch , defineProps, defineExpose, nextTick,reactive} from 'vue'
import { sign } from '@/api/room'
import { ElMessage } from 'element-plus'

const visible = ref(false)
const props = defineProps({
    videoObj: {
        type: Object,
        default: () => ({})
    }
})
const { videoObj } = toRefs(props)

const state = reactive({  
    timeHistory: new Map()  
});  

const closeVideo = () => {
    const videoEle = document.getElementById('video')
    state.timeHistory.set('video' + videoObj.value.ID, videoEle?.currentTime);
    visible.value = false
}
/* 弹出drawer */
const openDialog = () => {
    visible.value = true
    nextTick(() => {
        const videoEle = document.getElementById('video')
        let time = state.timeHistory.get('video' + videoObj.value.ID);
        if(!time){
            time = 0
        }
        // timeHistory[]
        videoEle.currentTime = time
    })
}
defineExpose({
    openDialog
})
const VideoUrl = ref('')
const videoPlay = () => {
    // debugger
    if (videoObj.value.entityId) {
        sign({
            id: videoObj.value.entityId,
            category: 2
        }).then(res => {
            console.log('sign----', res)
            if (res.errorCode === 0) {
                VideoUrl.value = videoObj.value.url || videoObj.value.imgUrl + '?sign=' + res.data.sign + '&t=' + res.data.t + '&us=' + res.data.us
            } else {
                ElMessage({
                    message: res.message || res.errorMsg || res.errMsg,
                    type: 'error'
                })
            }
        })
    } else {
        VideoUrl.value = videoObj.value.url
    }
}
watch(
    () => videoObj.value,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            VideoUrl.value = ''
            videoPlay()
        }
    },
    {
        immediate: true
    })

</script>

<style lang="scss" scoped>
.create-dialog {
    .videoCon {
        text-align: center;
    }

    // width: 323px;
    // padding: 25px 24px 35px;
    // background: #fff;
    // border-radius: 8px;
    .title {
        height: 25px;
        // background: #EEEEEE;
        position: relative;
        font-size: 18px;
        font-weight: bold;
        color: #333;
        line-height: 25px;
    }

    .closeX {
        position: absolute;
        width: 30px;
        height: 30px;
        background: url(@/assets/icons/close.png) no-repeat;
        background-position: center;
        background-size: contain;
        right: -50px;
        top: -10px;
        cursor: pointer;
    }

    .video {
        max-width: 100%;
        max-height: 70vh;
    }
}</style>