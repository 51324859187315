import axios from 'axios'
import { getBrowser } from './common'
import { useLocalStorage } from '@vueuse/core'
import { ElMessage } from 'element-plus'
import router from '@/router'
// const defaultBaseUrl = ''
// create an axios instance
import { getQueryBaseObj } from '@/utils/common'
import store from '@/store'
const urlToken = getQueryBaseObj().rt
console.log('urlToken-----',urlToken)
const service = axios.create({
    withCredentials: false,
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: `${window.location.protocol}${defaultBaseUrl}`,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
    headers: {
        'Content-Type': 'application/json'
    }
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        const loginUserFromStorage = useLocalStorage('loginUser',{})
        // const loginUser = JSON.parse(loginUserFromStorage.value)
        // 下面代码需要删掉
        // config.headers.pccToken = loginUserFromStorage.value?.pccToken
        const originUrl = window.location.origin
        // console.log('window.location.origin',window.location.origin,originUrl.indexOf('pre-pc.'))

        if(originUrl.indexOf('pre-pc.') > -1){
            // console.log('应该加预上线的header')
            config.headers.jcshadow = 21312
        }
        if(urlToken){
            config.headers.dcRefreshToken = urlToken
        }else{
            config.headers.pccToken = loginUserFromStorage.value?.pccToken
        }
        const deviceInfo = getBrowser()
        // console.log('deviceInfo',deviceInfo)
        config.headers.deviceId = deviceInfo.browser + deviceInfo.versions
        config.headers.dcChannel = 6
        // config.headers.jcshadow = 21312 // 预上线参数
        return config
    },
    (error) => {
        // do something with request error
        console.log('request error', error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data
        const code = response.data.code
        // console.log('response-----',res)
        // if the custom code is not 20000, it is judged as an error.
        if (code >= 400) {
            if(code === 6001) {
                ElMessage({
                    message: res.message,
                    type: 'error',
                    customClass:'jyx_message'
                })
                router.push('/login')
                return Promise.reject(res)
            }else if(code === 9999){
                return res
            }else if(code === 9005){
                ElMessage({
                    message: res.message,
                    type: 'error',
                    customClass:'jyx_message'
                })
                router.replace('/home')
            } else if (res.code === 1017) {
                ElMessage({
                    type: 'error', message: '登录失败，PC版本剧有戏仅提供DM带本使用，请您使用DM账号登录！', center: true,
                    customClass: 'jyx_message'
                })
            } else{
                ElMessage({
                    message: res.message,
                    type: 'error',
                    customClass:'jyx_message'
                })
                return Promise.reject(new Error(res.message || 'Error'))
            }
            
        } else {
            return res
        }
    },
    (error) => {
        if (error.response) {
            const res = error.response.data // for debug
            console.log('res-----',res)
            if(error.code === 'ERR_NETWORK'){
                store.commit('OPEN_GLOBALDIALOG', {
                    dialogVisible: true,
                    message: '网络出错了，请刷新重试',
                    btnText: '知道了'
                })
                return Promise.reject(res?.desc || error)
            }
            if (error.response.status === 401 && res.code !== '001') {
                console.log('>>>>>无权限')
            }
            if (error.response.status === 403) {
                res.desc = '您没有权限进行查询和操作!'
            }
            return Promise.reject(res?.desc || error)
        }
        console.log(error,'error===')
        return Promise.reject(error)
    }
)

export function request(config){
    return service(config)
}

export function postTokenCancle(url, params, source){
    service.interceptors.request.use(
        (config) => {
            // do something before request is sent
            config.headers = Object.assign(config.headers,{
                cancelToken:source.token
            })
            return config
        }
    )
    return service({
        url,
        method: 'post',
        data:params
    })
}
