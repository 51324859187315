<template>
    <el-dialog v-model="visible" custom-class="img_viewer_dialog" @close="closeDialog">
        <div class="img_viewer_continer">
            <div class="img_viewer_main" @click="handleClose" oncontextmenu="return false;">
                <div class="img_viewer_btn">
                    <div class="img_viewer_action_btn left_btn" @click.stop="handlePrev" v-if="hasPre">
                        <el-icon><ArrowLeft /></el-icon>
                    </div>
                </div>
                <div class="img_viewer_list" ref="root">
                    <slot
                    :images="images"
                    :options="options"
                    />
                </div>
                <div class="img_viewer_btn">
                    <div class="img_viewer_action_btn right_btn" @click.stop="handleNext" v-if="hasNext">
                        <el-icon><ArrowRight /></el-icon>
                    </div>
                </div>
            </div>
            <div class="img_viewer_footer">
                <div class="img_viewer_toolbar" alt="">
                    <img src="@/assets/icons/viewer_scale1.png" class="toolbar_action viewer_scale1" alt="" @click.stop="handleScale1">
                    <img src="@/assets/icons/viewer_zoomout.png" class="toolbar_action viewer_zoomout" alt="" @click.stop="handleZoomOut">
                    <div class="viewer_zoomnum">{{ zoomNum }}</div>
                    <img src="@/assets/icons/viewer_zoomin.png" class="toolbar_action viewer_zoomin" alt="" @click.stop="handleZoomin">
                    <img src="@/assets/icons/viewer_rotate.png" class="toolbar_action viewer_rotate" alt="" @click.stop="handleRotate">
                </div>
                <div class="img_viewer_actions">
                    <slot name="actions"></slot>
                </div>
            </div>

        </div>
    </el-dialog>


</template>

<script setup>
import {
    nextTick,
    onMounted,
    onUnmounted,
    ref,
    toRefs,
    watch,
    computed
} from 'vue'
import Viewer from 'viewerjs'
import {ArrowLeft,ArrowRight} from '@element-plus/icons-vue'
const emits = defineEmits(['inited','close'])
const props = defineProps({
    images: {
        type: Array,
        default: () => ([]),
    },
    rebuild: {
        type: Boolean,
        default: false,
    },
    trigger: {
        type: Object,
        default: null,
    },
    options: {
        type: Object,
        default: () => ({}),
    },
    currentIndex: {
        type: Number,
        default: 0
    },
})
const {images,rebuild,trigger,options,currentIndex} = toRefs(props)
const root = ref(null)
const $viewer = ref(null)
const currentZoom = ref(1)
const defaultOptions = {
    inline:true,
    backdrop:false,
    navbar:false,
    button:false,
    title:false,
    toolbar:false,
    tooltip:false,
    zoom:(evt)=>{
        console.log('触发zoom事件',evt)
        currentZoom.value = evt.detail.ratio
    }
}
const zoomNum = computed(()=>{
    return Math.floor(currentZoom.value * 100) + '%'
})
const hasNext = computed(() => {
    if ($viewer.value) {
        return $viewer.value.index < $viewer.value.length-1
    }
    return false
})
const hasPre = computed(() => {
    if ($viewer.value) {
        return $viewer.value.index > 0
    }
    return false
})
const visible = ref(true)
const closeDialog = ()=>{
    emits('close')
}
const handleClose = ()=>{
    emits('close')
}
const handlePrev = ()=>{
    $viewer.value.prev()
}
const handleNext = ()=>{
 
    $viewer.value.next()
}
const handleScale1 = ()=>{
    $viewer.value.zoomTo(1)
}
const handleZoomOut = ()=>{
    $viewer.value.zoom(0.5)
}
const handleZoomin = ()=>{
    $viewer.value.zoom(-0.5)

}
const handleRotate = ()=>{
    $viewer.value.rotate(-90)
}






const createViewer = ()=> {
    console.log('options.value',options.value)
    options.value.initialViewIndex = currentIndex.value
    const finallOptions = Object.assign(options.value,defaultOptions)
    $viewer.value = new Viewer(root.value, finallOptions)
    emits('inited', $viewer.value)
}
const destroyViewer =()=> {
    console.log('销毁了$viewer')
    $viewer.value && $viewer.value.destroy()
}
const rebuildViewer =()=> {
    destroyViewer()
    createViewer()
}

// create、update
const updateViewer =()=> {
    console.log('执行了updateViewer',$viewer.value)
    if ($viewer.value) {
        $viewer.value.update()
        emits('inited', $viewer.value)
    }
    else {
        createViewer()
    }
}
const changeViewer =()=> {
    if (rebuild.value) {
        rebuildViewer()
    }
    else {
        updateViewer()
    }
}
const watchOptions = { deep: true }
watch(() => images.value, () => {
    console.log('images.value变化了')
    nextTick(() => {
        changeViewer()
    })
}, watchOptions)
watch(() => trigger.value, () => {
    nextTick(() => {
        changeViewer()
    })
}, watchOptions)
watch(() => options.value, () => {
    nextTick(() => {
        rebuildViewer()
    })
}, watchOptions)
defineExpose({
    createViewer,
    $viewer
})
// lifecycle hooks
onMounted(() => {
    // createViewer()
})
onUnmounted(() => destroyViewer())


</script>

<style lang="scss">
    .img_viewer_dialog{
        background: none;
        width: 100%;
        /* height: 100%; */
        /* box-sizing: border-box; */
        padding: 0;
        margin: 0;
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            padding: 0;
        }
    }
</style>
<style lang="scss" scoped>

.img_viewer_continer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .img_viewer_main{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .img_viewer_btn {
            width: 44px;
            height: 44px;
        }
        .img_viewer_action_btn{
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background: var(--main-button-color-8);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: rgba($color: #FFF, $alpha: 0.8);
            cursor: pointer;
            position: relative;
            z-index: 10;
            &:hover{
                background: var(--main-button-color-3);
            }
            &.left_btn{

            }
            &.right_btn{

            }
        }

        .img_viewer_list{

        }
    }
    .img_viewer_footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        .img_viewer_toolbar{
            display: flex;
            align-items: center;
            width: 260px;
            justify-content: space-evenly;
            .toolbar_action{
                cursor: pointer;
                &:hover{
                    scale: 1.1;
                }
            }
            .viewer_scale1{
                width: 18px;
                height: 15px;
            }
            .viewer_zoomout{
                width: 17px;
                height: 17px;
            }
            .viewer_zoomin{
                width: 17px;
                height: 17px;
            }
            .viewer_rotate{
                width: 17px;
                height: 16px;
            }
            .viewer_zoomnum{
                color: #FFF;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                width: 40px;
                text-align: center;
            }
        }

        .img_viewer_actions{
            margin-top: 20px;
        }
    }

}

</style>
