import {reactive,ref,watch} from 'vue'
export default function useVolume(stream){
    const volume = ref(0)
    const newRef = reactive({current:{}})
    const onmessage = (event) => {
        
        if (!newRef.current.audioContext) {
            return
        }
        if (event.data.volume) {
            volume.value = Math.round(event.data.volume * 200)
            // if(volume.value )
            // console.log('监听onmessage',volume.value)
        }
    }
    const disconnectAudioContext = () =>{
        if (newRef.current.node) {
            try {
                newRef.current.node.disconnect()
            } catch (errMsg) {}
        }
        if (newRef.current.source) {
            try {
                newRef.current.source.disconnect()
            } catch (errMsg) {}
        }
        newRef.current.node = null
        newRef.current.source = null
        newRef.current.audioContext = null
        volume.value = 0
    }
    const connectAudioContext = async (mediaStream) =>{
        console.log('mediaStream-----',mediaStream)
        if (newRef.current.audioContext) {
            disconnectAudioContext()
        }
        try {
            newRef.current.audioContext = new AudioContext()
            await newRef.current.audioContext.audioWorklet.addModule('https://ycimg-m.duoku.com/cimages/img/promo/source/pages/activity/jyx/vumeter.js')
            if (!newRef.current.audioContext) {
                return
            }
            newRef.current.source = newRef.current.audioContext.createMediaStreamSource(mediaStream)
            newRef.current.node = new AudioWorkletNode(newRef.current.audioContext, 'vumeter')
            
            newRef.current.node.port.onmessage = onmessage
            
            newRef.current.source.connect(newRef.current.node).connect(newRef.current.audioContext.destination)
        } catch (errMsg) {
            disconnectAudioContext()
        }
    }
    watch(()=>stream,(nVal,oVal)=>{
        console.log('stream变化了-----',nVal)
        if (!nVal) {
            return () => {}
        }
        connectAudioContext(nVal)
        return () => {
            disconnectAudioContext(nVal)
        }
    },{
        immediate:true
    })

    return volume
}