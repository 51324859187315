<template>
    <el-container class="main_container">
      <el-header class="layout_header">
        <Header></Header>
      </el-header>
      <el-main class="layout_main">
        <router-view :key="key" />
      </el-main>
    </el-container>    
</template>
    
<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Header from './Header'
const route = useRoute()
const key = computed(()=>{
    return route.path
})
</script>
    
<style lang="scss" scoped>

.main_container{
    height: 100%;
    min-width: 1200px;
    min-height: 600px; 
    background: var(--main-bg-color);
}
.layout_header{
    height: 60px;
    background-color: var(--main-bg-color);
    border-bottom: 1px solid var(--main-bg-lighter-color-2);
}
.layout_main{
  padding: 0;
}
</style>