<template>
  <div :class="['message-input-button', isH5 && 'message-input-button-h5']">
    <button v-if="enableSend" class="message-input-button-cont" data-type="text" @click="sendMessage" :disabled="false">
      <p class="message-input-button-hover">
        {{ $t('TUIChat.按Enter发送，Ctrl+Enter换行') }}
      </p>
      {{ $t('发送') }}
    </button>
  </div>
</template>
<script setup lang="ts">
import { defineProps, toRefs, defineEmits, ref } from 'vue';
const props = defineProps({
  enableSend: {
    type: Boolean,
    default: true,
  },
  messages: {
    type: Array,
    default: () => [],
  },
  isH5: {
    type: Boolean,
    default: true,
  },
});
const { enableSend } = toRefs(props);
const emits = defineEmits(['sendMessage']);
const sendMessage = () => {
  emits('sendMessage');
}
</script>
<style scoped lang="scss">
@import url('../../../styles/common.scss');
@import url('../../../styles/icon.scss');
.message-input-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  &-h5{
    position: static;
  }
  &-cont {
    padding: 5px 26px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
    background: #4A4E76;
    color: rgba(255, 255, 255, 0.41);
    letter-spacing: 0;
    cursor: pointer;
  }
  &:hover {
    .message-input-button-hover {
      display: flex;
    }
  }
  &-hover {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 120%;
    word-break: keep-all;
    height: 30px;
    white-space: nowrap;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 5px 10px;
    border-radius: 3px;
    background: #000000;
    color: #ffffff;
    opacity: 0.3;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      right: -20px;
      border: 10px solid transparent;
      border-left: 10px solid #000000;
    }
  }
}
</style>
